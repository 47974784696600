var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.msg
    ? _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center mt-2" },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { show: "", variant: _vm.typeMessage }
                    },
                    [_vm._v(_vm._s(_vm.msg))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }