var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-icon", {
    attrs: { icon: "three-dots", animation: "cylon", "font-scale": "4" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }