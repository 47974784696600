<template>
    <div>
        <b-row>
            <b-col cols="2">
                <b-form-select v-model="selected" size="sm" class="mt-3" v-if="domains" @change="selectDomain">
                    <b-form-select-option :value="null">sort by domain</b-form-select-option>
                    <b-form-select-option v-for="domain in domains" :value="domain['@id']" :key="domain.id" :style="'color: '+domain.color">{{ $t('domaines.'+domain.title) }}</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="2">
                <b-form-select v-model="selected2" size="sm" class="mt-3" v-if="topics && selected" @change="selectTopic">
                    <b-form-select-option :value="null">sort by topic</b-form-select-option>
                    <b-form-select-option v-for="topic in topics" :value="topic['@id']" :key="topic.id">{{ $t('domaines.'+topic.title) }}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-button variant="primary" disabled v-if="selected && selected2 && !examples">
            <b-spinner small type="grow"></b-spinner>
            Loading...
        </b-button>
        <b-row>
            <b-col
                cols="12" sm="12" md="6" lg="2"
                class="mb-2 mt-2"
                v-for="(example, index) in examples"
                :key="example.id"
                v-if="selected && selected2 && examples && selected === example.topic.domain['@id'] && selected2 === example.topic['@id']"
                >
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(setExample(example))">
                        <b-card
                            :bg-variant="example.isFree ? 'danger light' : ''"
                            :text-variant="example.isFree ? 'white' : ''"
                        >
                            <b-card-sub-title
                                v-html="$t('examples.'+example.title)"
                                :sub-title-text-variant="example.isFree ? 'white' : ''"
                            />
                            <b-card-text>
                                <b-row class="mt-2">
                                    <b-col cols="4" class="mr-0 pt-1">Minimum delay </b-col>
                                    <b-col cols="2" class="pl-0">
                                        <b-form-input size="sm" v-model="example.minDelay" v-if="example" type="number"/>
<!--                                        <b-form-input size="sm" v-model="newPartner.name" v-else></b-form-input>-->
                                    </b-col>
                                    <b-col cols="4" class="mr-0 pt-1">Maximum delay </b-col>
                                    <b-col cols="2" class="pl-0">
                                        <b-form-input size="sm" v-model="example.maxDelay" v-if="example" type="number"/>
                                        <!--                                        <b-form-input size="sm" v-model="newPartner.name" v-else></b-form-input>-->
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-row class="mt-2">
                                            <b-col cols="4">is free ?</b-col>
                                            <b-col cols="2">
                                                <b-icon v-if="example"
                                                        :icon="example.isFree ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                                        :variant="example.isFree ? 'white' : 'danger'"
                                                        v-model="example.isFree"
                                                        @click="toggle( 'isFree', example)"
                                                />
                                                <!-- <b-icon v-else
                                                        :icon="newPartner.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                                        :variant="newPartner.state ? 'success' : 'danger'"
                                                        v-model="newPartner.state"
                                                        @click="toggle( 'state', newPartner)"
                                                />-->
                                            </b-col>
                                            <b-col cols="4">is online ?</b-col>
                                            <b-col cols="2">
                                                <b-icon v-if="example"
                                                        :icon="example.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                                        :variant="example.state ? 'success' : 'danger'"
                                                        v-model="example.state"
                                                        @click="toggle( 'state', example)"
                                                />
<!--                                                <b-icon v-else
                                                        :icon="newPartner.state ? 'bookmark-check' : 'bookmark-x'" font-scale="2"
                                                        :variant="newPartner.state ? 'success' : 'danger'"
                                                        v-model="newPartner.state"
                                                        @click="toggle( 'state', newPartner)"
                                                />-->
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-input-group size="sm" class="mt-2">
                                    <b-form-input v-model="example.title"/>
                                    <b-input-group-append>
                                        <b-button variant="primary" type="submit">save</b-button>
                                        <b-button variant="danger" type="submit">delete</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-card-text>
                        </b-card>
                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from "axios";
    import domainsJson from '../../../../files/domains.json'

    export default {
        name: "Examples-Admin",
        props:["makeToast"],
        data() {
            return {
                selected: null,
                selected2: null,
                domains: domainsJson,
                topics: null,
                examples: null,
                titleNotification: 'Example Notification',
            }
        },
        methods: {
            toggle(element, partner) {
                partner[element] = !partner[element]
            },
            selectDomain() {
                this.examples = null
                this.selected2 = null
                const url = this.$store.state.apiUrl + '/api/topics?domain='+this.selected+'pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.topics = response.data["hydra:member"]
                    }
                )
            },
            selectTopic() {
                this.examples = null
                const url = this.$store.state.apiUrl + '/api/examples?topic='+this.selected2+'&pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.examples = response.data["hydra:member"]
                    }
                )
            },
            setExample(example) {
                let updateExample = {}
                updateExample.title = example.title
                const url = this.$store.state.apiUrl + example["@id"]
                axios.patch(
                    url,
                    updateExample,
                    {
                        'headers': {
                            'Content-type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(example.title + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
