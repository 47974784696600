<template>
    <div>
        <div class="titre mb-2" v-html="$t('website.aide')"></div>
        <div v-html="$t('website.00d_aide')"></div>
    </div>
</template>

<script>
    export default {
        name: "Aide"
    }
</script>

<style scoped>

</style>
