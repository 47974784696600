<template>
    <div>
        <top-header/>
<!--        <rights-header/>-->
        <menu-header/>
        <menu-domains v-if="domains"/>
    </div>
</template>

<script>
    import MenuDomains from "./MenuDomains";
    import MenuHeader from "./Menu";
    import RightsHeader from "./Rights";
    import TopHeader from "./Top";

    export default {
        name: "HeaderMain",
        components: {MenuDomains, MenuHeader, RightsHeader, TopHeader},
        computed: {
            domains() {
                return this.$store.state.domains
            }
        }
    }
</script>

<style scoped>
</style>
