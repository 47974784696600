<template>
    <div class="mb-2">
        <b-row>
            <b-col cols="2">
                <b-button variant="primary" disabled v-if="loader">
                    <b-spinner small type="grow"></b-spinner>
                    Loading...
                </b-button>
                <b-button variant="primary" size="sm" v-else-if="user.roles.includes('super_admin')" @click="deleteKeycloakClients">
                    delete keycloak users
                </b-button>
            </b-col>
            <b-col v-if="clients">
                <b-row>
                    <b-col class="align-content-center">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            last-number
                            aria-controls="my-table"
                            @change="goPage"
                        ></b-pagination>
                    </b-col>
                    <b-col cols="2">
                        <b-row>
                            <b-col v-for="pr in prices" :key="pr.id" cols="6">
                                <b-form-checkbox
                                    switch
                                    size="sm"
                                    v-model="price"
                                    :value="pr.name"
                                    @change="getClients"
                                >
                                    {{ pr.name }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="2">
                        <b-input
                            v-model="search"
                            type="search"
                            @input="searchClients"
                            size="sm"
                            placeholder="search (email-firstname-lastname)"
                        />
                    </b-col>
                    <b-col cols="1">
                        <div>
                            <b-form-checkbox
                                switch
                                size="sm"
                                v-model="actif"
                                @change="getClients"
                            >
                                actif
                            </b-form-checkbox>
                        </div>
                        <div>
                            <b-form-checkbox
                                switch
                                size="sm"
                                v-model="unsecure"
                                @change="getClients"
                            >
                                unsecure
                            </b-form-checkbox>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-badge v-if="clients" :variant="actif ? 'success' : 'danger'">{{ rows }}</b-badge>
        <b-card-group columns v-if="clients">

            <b-card
                v-for="client in clients"
                :key="client.id"
                :header="client.firstname+' '+client.lastname"
            >
                <client-card :client="client" :check-keycloak="checkKeycloak" :get-role="getRole"/>

            </b-card>
        </b-card-group>
        <modal-client/>
    </div>
</template>

<script>
    import axios from "axios";
    import ClientCard from "../../../components/admin/Client";
    import ModalClient from "../../../components/admin/ModalClient";
    import querystring from "querystring";

    export default {
        name: "Clients",
        data() {
            return {
                loader: null,
                prices: null,
                clients: null,
                clientActif: null,
                actif: true,
                showModal: false,
                dateNow: new Date(),
                perPage: 20,
                currentPage: 1,
                rows: null,
                search: null,
                price: null,
                unsecure: null,
            }
        },
        computed: {
            user() {
                return this.$store.state.currentUser
            }
        },
        components: {ClientCard, ModalClient},
        mounted() {
            this.getPrices()
        },
        methods: {
            getPrices() {
                this.loader = true
                const url = this.$store.state.apiUrl + '/api/prices?state=true'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.prices = response.data["hydra:member"]
                        this.getClients()
                    }
                )
            },
            searchClients() {
                this.currentPage = 1
                this.getClients()
            },
            getClients() {
                this.loader = true
                let url = this.$store.state.apiUrl + '/api/clients?page=' + this.currentPage +
                    '&itemsPerPage=' + this.perPage + '&actif=' + this.actif

                if(this.search) {
                    url += '&search=' + this.search
                }
                if(this.price) {
                    url += '&price=' + this.price
                }
                if(this.unsecure) {
                    url += '&keycloakId=false'
                }
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.rows = response.data["hydra:totalItems"]
                        this.clients = response.data["hydra:member"]
                        this.loader = null
                        this.checkKeycloakClients()
                    }
                )
            },
            goPage(page) {
                this.currentPage = page
                this.getClients()
            },
            getRole(client) {
                const url = this.$store.state.keycloakApiUrl + "/api/roles/" + client.keycloakId
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        if (Object.keys(response.data).length === 0) {
                            this.setRole(client)
                        }
                    }
                )
            },
            setRole(client) {
                const url = this.$store.state.keycloakApiUrl + "/api/roles/client_" + client.lastOrder.price.name +
                    "/users/" + client.keycloakId
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                    }
                )
            },
            checkKeycloakClients() {
                const self = this
                if(this.clients != undefined) {
                    this.clients.forEach(function(client, index){
                        if ("" === client['keycloakId'] || undefined === client['keycloakId']) {
                            self.checkKeycloak(client)
                        }
                    })
                }
            },
            checkKeycloak(client) {
                const url = this.$store.state.keycloakApiUrl + "/api/users/" + client.email
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        const keycloakUser = response.data
                        if (keycloakUser.length === 0) {
                            this.setKeycloak(client)
                        } else if (!client.keycloakId){
                            this.setKeycloakId(client["@id"], keycloakUser[0]["id"])
                        }
                    }
                )
            },
            deleteKeycloakClients() {
                let url = this.$store.state.apiUrl + '/api/clients?pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        const clients = response.data["hydra:member"]
                        const self = this
                        clients.forEach(function(client, index){
                            if ("" !== client['keycloakId'] && "axcrete@free.fr" !== client['email']) {
                                self.deleteKeycloak(client)
                            }
                        })
                    }
                )
            },
            deleteKeycloak(client) {
                const url = this.$store.state.keycloakApiUrl + "/api/users/delete/" + client['keycloakId']
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.removeKeycloakId(client["@id"])
                    }
                )

            },
            setKeycloak(client) {
                let user = {}
                user.firstname = client.firstname
                user.lastname = client.lastname
                user.email = client.email
                user.displayName = client.email
                user.password = client.password

                const url = this.$store.state.keycloakApiUrl + "/api/users/create"
                axios.post(
                    url,
                    querystring.stringify(user),
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.checkKeycloak(client)
                    }
                )
            },
            setKeycloakId(id, keycloakId) {
                const url = this.$store.state.apiUrl + id
                let user = {}
                user.keycloakId = keycloakId
                this.patchUser(url, user)
            },
            removeKeycloakId(id) {
                const url = this.$store.state.apiUrl + id
                let user = {}
                user.keycloakId = ''
                this.patchUser(url, user)
            },
            patchUser(url, user) {
                axios.patch(
                    url,
                    user,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                    }
                )
            }

        }
    }
</script>

<style scoped>
</style>
