var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "page" },
    [
      _c("b-row", { staticClass: "titre mb-4" }, [
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("website.01_page_titre")) }
        })
      ]),
      _vm._v(" "),
      _vm._l(_vm.domains, function(domain) {
        return domain.menu
          ? _c(
              "b-row",
              { key: domain.id, staticClass: "topic" },
              [
                _c(
                  "b-col",
                  { staticClass: "titre", attrs: { sm: "12", md: "3" } },
                  [
                    _c("router-link", {
                      attrs: {
                        to: {
                          name: "domaine",
                          params: {
                            slug: domain.title,
                            id: domain.id,
                            lng: _vm.lng
                          }
                        }
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("menu." + domain.title))
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-row",
                      _vm._l(domain.topics, function(topic) {
                        return _c(
                          "b-col",
                          {
                            key: topic.id,
                            staticClass: "mb-3",
                            attrs: { sm: "12", md: "4" }
                          },
                          [
                            _c("router-link", {
                              attrs: {
                                to: {
                                  name: "domaine",
                                  params: {
                                    slug: domain.title,
                                    id: domain.id,
                                    lng: _vm.lng
                                  }
                                }
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  "&bull; " + _vm.$t("domaines." + topic.title)
                                )
                              }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }