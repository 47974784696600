<template>
    <tab-input
        url="currencies"
        title="Currencies"
        add-text="add a currency"
        :make-toast="makeToast"
        title-notification="Currency Notification"
        name="Currency"
        generate-file="currencies"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Currencies-Admin",
        props: ["makeToast"],
        components: {TabInput},
        data() {
            return {
                toastVariant: null,
            }
        }
    }
</script>

<style scoped>
</style>
