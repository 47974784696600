<template>
    <tab-input
        url="countries"
        title="Countries"
        add-text="add a country"
        :make-toast="makeToast"
        title-notification="Country Notification"
        name="Country"
        generate-file="countries"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Countries-Admin",
        props: ["makeToast"],
        components: {TabInput},
        data() {
            return {
                toastVariant: null,
            }
        }
    }
</script>

<style scoped>
</style>
