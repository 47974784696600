<template>
    <div>
        <div>{{ index }}</div>
        <div v-html="$t('website.' + step)"></div>
    </div>
</template>

<script>
    export default {
        name: "StepDomain",
        props: ['index', 'step']
    }
</script>

<style scoped>

</style>
