import Vue from "vue";
import axios from "axios";
import store from "./store";
import router from './route';
import querystring from "querystring";

const clientKeycloak = "calkulator-admin";
let timer

const AccountPlugin = {
    install(Vue, options) {
        Vue.prototype.$getAccount = (url, accessToken, user) => {
            if(user && user.email) {
                return axios.get(
                    url + "/api/clients?email=" + user.email,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + accessToken
                        }
                    }
                ).then(
                    response => response.data["hydra:member"][0]
                ).catch(
                    //this.$logout()
                )
            } else {
                return null;
            }
        }

        Vue.prototype.$checkAccessPage = (account, example) => {
            const check =(
                account &&
                (
                    example.isFree ||
                    checkActiveOrders(example) ||
                    account.enterprise && account.enterprise.lastOrder
                )
            )
            if(check === false) {
                router.push({name: 'home', params: {lng: store.state.actLng}})
            }
        }

        function checkActiveOrders (account, example) {
            let check = false
            if(account.orders) {
                account.orders.forEach((order) => {
                    if (order.active) {
                        const tcheck = (!order.price || order.price.name !== 'free') &&
                            (
                                (order.price && order.price.name !== 'solo') ||
                                (order.domain && order.domain.title === example.topic.domain.title) ||
                                (order.example && order.example === example['@id'])
                            )
                        if (tcheck) {
                            check = tcheck
                        }
                    }
                })
            }
            return check
        }

        Vue.prototype.$getConfig = () => {
            return axios.get(
                "/config/get-config"
            ).then(response => response.data)
        }

        Vue.prototype.$accessLogin = (username, password) => {
            //console.log('accessLogin')
            const tokenUrl = store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/token'

            return axios.post(
                tokenUrl,
                querystring.stringify({
                    grant_type: 'password',
                    client_id: clientKeycloak,
                    username: username,
                    password: password,
                }),
                {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            ).then(response => response.data)
        }

        Vue.prototype.$logout = () => {
            if(!store.state.refreshToken) {
                return false
            }
            const logoutUrl = store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/logout'
            return axios.post(
                logoutUrl,
                querystring.stringify({
                    client_id: clientKeycloak,
                    refresh_token: store.state.refreshToken,
                }),
            ).then(response => response.data)
        }

        Vue.prototype.$setTokens = (data) => {
            store.commit('setAccessToken', data ? data.access_token : null)
            store.commit('setRefreshToken', data ? data.refresh_token : null)
            let tokensExpiry = {}
            if(data) {
                const tokenDate = new Date();
                tokensExpiry = {
                    tokenDate: tokenDate.toISOString(),
                    accessExpiry: Vue.moment(tokenDate.toISOString()).add(data.expires_in, 'seconds').toISOString(),
                    refreshExpiry: Vue.moment(tokenDate.toISOString()).add(data.refresh_expires_in, 'seconds').toISOString(),
                }
            }
            store.commit('setTokensExpiry', data ? tokensExpiry : null)

            return tokensExpiry
        }

        Vue.prototype.$getUserinfo = () => {
            const userinfoUrl = store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/userinfo'

            return axios.get(
                userinfoUrl,
                {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Bearer ' + store.state.accessToken
                    }
                }
            ).then(response => response.data)
        }

        Vue.prototype.$checkUser = (user) => {
            const url = store.state.apiUrl + "/api/clients?email=" + user.email
            return axios.get(
                url,
                {
                    'headers': {
                        'Authorization': 'Bearer ' + store.state.accessToken
                    }
                }
            ).then(response => response.data["hydra:member"][0])
        }

        Vue.prototype.$checkSession = (client) => {
            //console.log('checkSession')
            const url = store.state.keycloakApiUrl + "/api/users/sessions/" + client.keycloakId
            return axios.get(
                url,
                {
                    'headers': {
                        'Authorization': 'Bearer ' + store.state.accessToken
                    }
                }
            ).then(response => response.data)
        }

        Vue.prototype.$refresh = () => {
            //console.log('refresh')
            const tokenUrl = store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/token'

            return axios.post(
                tokenUrl,
                querystring.stringify({
                    grant_type: 'refresh_token',
                    client_id: clientKeycloak,
                    refresh_token: store.state.refreshToken
                }),
                {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            ).then(response => response.data)
        }

        Vue.prototype.$clearStore = () => {
            store.commit('setUser', null)
            store.commit('setAccessToken', null)
            store.commit('setRefreshToken', null)
            store.commit('setTokensExpiry', null)
            store.commit('setCurrentPrice', null)
            store.commit('setOrderBill', null)
            store.commit('setOptions', null)
            store.commit('setPrice', null)
            store.commit('setPriceOptions', null)
            store.commit('setDomainStep', null)
            store.commit('setElement', {key: 'discountCode', value: null})
            store.commit('setElement', {key: 'calcul', value: null})
            store.commit('setElement', {key: 'domain', value: null})
            store.commit('setElement', {key: 'example', value: null})
            store.commit('setElement', {key: 'priceoption', value: null})
            store.commit('setElement', {key: 'exemple', value: null})
        }
    }
}
Vue.use(AccountPlugin)
