<template>
    <tab-input
        url="activities"
        title="Activities types"
        add-text="add an activity type"
        :make-toast="makeToast"
        title-notification="Activity Notification"
        generate-file="activitiestypes"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Activities-Admin",
        props: ["makeToast"],
        components: {TabInput},
        data() {
            return {
                toastVariant: null,
            }
        }
    }
</script>

<style scoped>
</style>
