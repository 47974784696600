<template>
    <b-container class="mb-5">
        <b-row class="mt-5">
            <b-col sm="12" md="6">
                <div class="titre mb-4" v-html="$t('tarifs.achat_exemple_unite')"></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3">
                <b-form-select
                    v-model="selected"
                    size="sm"
                    v-if="domains"
                    @change="selectDomain"
                >
                    <b-form-select-option :value="null">--- {{ $t('tarifs.choixdomaine') }} ---</b-form-select-option>
                    <b-form-select-option
                        v-for="domain in domains"
                        :value="domain"
                        :key="domain.id"
                        v-if="domain.menu"
                    >
                        {{ $t('domaines.'+domain.title) }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="3">
                <b-form-select
                    v-model="selected2"
                    size="sm"
                    v-if="topics && selected"
                    @change="selectTopic"
                >
                    <b-form-select-option :value="null">--- {{ $t('tarifs.choixtopic') }} ---</b-form-select-option>
                    <b-form-select-option
                        v-for="topic in topics"
                        :value="topic"
                        :key="topic.id"
                    >
                        <span v-html="$t('domaines.'+topic.title)"/>
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="3">
                <b-form-select
                    v-model="selected3"
                    size="sm"
                    v-if="topics && selected && examples && selected2"
                    @change="selectExemple"
                >
                    <b-form-select-option :value="null">--- {{ $t('tarifs.choixexemple') }} ---</b-form-select-option>
                    <b-form-select-option
                        v-for="example in examples"
                        :value="example"
                        :key="example.id"
                    >
                        {{ $t('examples.' + example.title) |striphtml }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <hr/>
        <b-row v-if="selected && selected2 && selected3" class="mt-3">
            <b-col>
                <b-row>
                    <b-col>
                        <b-icon icon="cart-plus-fill" font-scale="2" variant="primary"/>
                        {{ $t('tarifs.abonner_recap') }} :
                        <span v-html="$t('domaines.' + selected.title)"/> /
                        <span v-html="$t('domaines.' + selected2.title)"/> /
                        <span v-html="$t('examples.' + selected3.title)"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div class="tarif">{{ amount }}<span class="devise">€</span></div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col>
                        <b-button variant="primary" size="sm">{{ $t('website.valider') }}</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
    import domainsJson from "../../../../files/domains.json";
    import pricesJson from "../../../../files/prices.json";
    import priceOptionsJson from "../../../../files/price-options.json";
    import axios from "axios";

    export default {
        name: "Example",
        data() {
            return {
                account: null,
                selected: null,
                selected2: null,
                selected3: null,
                domains: domainsJson,
                topics: null,
                examples: null,
                prices: pricesJson,
                priceOptions: priceOptionsJson,
            }
        },
        created() {
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    if(!this.account.lastOrder) {
                        this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                    }
                }
            )
        },
        computed: {
            amount() {
                let value = 0
                this.prices.forEach((price) => {
                    if(price.name === this.account.lastOrder.price.name) {
                        Object.keys(this.priceOptions).forEach((key) => {
                            if(key === price.id + '_9') {
                                value = this.priceOptions[key].value
                                this.$store.commit('setElement', {'key': 'priceoption', 'value': this.priceOptions[key]})
                            }
                        })
                    }
                })
                return value
            }
        },
        methods: {
            selectDomain() {
                this.examples = null
                this.selected2 = null
                const url = this.$store.state.apiUrl + '/api/topics?domain=' + this.selected['@id'] + 'pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.topics = response.data["hydra:member"]
                    }
                )
            },
            selectTopic() {
                this.examples = null
                const url = this.$store.state.apiUrl + '/api/examples?topic=' + this.selected2['@id'] + '&pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.examples = response.data["hydra:member"]
                    }
                )
            },
            selectExemple(example) {
                this.$store.commit('setElement', {'key': 'exemple', 'value': example})
                this.$router.push({name: 'example-paiement', params: {lng: this.$store.state.actLng}})
            },
        }
    }
</script>

<style scoped>

</style>
