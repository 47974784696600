<template>
    <div>
        <b-modal id="modalClient">
            <b-card
                v-if="client"
                :header="client.firstname+' '+client.lastname"
            >
                <b-card-text>
                    <template>
                        <b-row>
                            <b-col cols="10">
                                <div class="mb-2"><span v-if="client.society">{{ client.society }}</span>&nbsp;</div>
                                <div><b-icon-telephone-fill variant="info" class="mb-1"/> {{ client.phone }}</div>
                                <div>
                                    <b-icon-signpost variant="info"/> {{ client.address }} {{ client.zipcode }}
                                    {{ client.city }} <span v-if="client.country">- {{ $t('countries.'+client.country.code) }}</span>
                                </div>
                            </b-col>
                            <b-col>
                                <div v-if="client.keycloakId" class="text-right"><b-icon-lock-fill variant="primary"/></div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col>
                                <div v-html="$t('website.00b_profil')" class="font-weight-bold"></div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div v-html="$t('countries.'+client.countryProfil.code)" v-if="client.countryProfil"></div>
                            </b-col>
                            <b-col>
                                <div v-html="$t('devises.'+(client.currency.code.toLowerCase()))" v-if="client.currency"></div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div v-html="$t('website.tauxpersonnalise') + ' ' + client.hourRate" v-if="client.hourRate"></div>
                                <div v-html="$t('website.tvapersonnalise') + ' ' + client.vatRate +'%'" v-if="client.vatRate"></div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div v-html="$t('website.enquete') + ' : ' + (client.ckFigure ? $t('website.oui') : $t('website.non'))"/>
                            </b-col>
                        </b-row>
                        <b-row v-if="client.profil">
                            <b-col>
                                <div v-html="$t('website.00b_profildesigner') + ' : ' + $t('website.'+client.profil.name)"/>
                            </b-col>
                        </b-row>
                        <b-row v-if="client.activity">
                            <b-col>
                                <div v-html="$t('website.00b_activite') + ' : ' + $t('website.'+client.activity.name)"/>
                            </b-col>
                        </b-row>
                    </template>
                    <div class="mt-2">
                        <b-list-group>
                            <b-list-group-item
                                v-for="order in client.orders"
                                :key="order.id"
                                :variant="order.active ? 'success' : (order.paymentDate ? '' : 'danger')"
                                class="d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <div>
                                        Commande n°{{ order.id }} - {{ order.amount }}€
                                        <span v-if="order.subscriptionType && order.paymentDate">
                                            ({{ order.subscriptionType.name }} {{ order.paymentDate | moment('DD/MM/YYYY')}})
                                        </span>
                                        <h6><b-badge>{{ order.creationDate | moment('DD/MM/YYYY') }}</b-badge></h6>
                                    </div>
                                    <div v-if="order.price">
                                        <span class="text-primary priceName" v-if="!order.example">{{ order.price.name }}</span>
                                    </div>
                                    <div v-else>
                                        <span v-html="$t('domaines.' + order.example.topic.domain.title)"/> /
                                        <span v-html="$t('domaines.' + order.example.topic.title)"/> /
                                        <span v-html="$t('examples.' + order.example.title)"/>
                                    </div>
                                    <div v-if="order.paymentDate">
                                        => {{ order.validationDate | moment('DD/MM/YYYY') }}
                                    </div>
                                </div>
                                <a href="#" v-if="order.paymentDate" @click="generateBill(order)">
                                    <b-badge variant="primary" pill v-if="order.paymentDate">
                                        <b-icon-download font-scale="2" class="mt-1 mb-1"/>
                                    </b-badge>
                                </a>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                </b-card-text>
            </b-card>
        </b-modal>
        <vue-html2pdf
            :show-layout="false"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="confirmation"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-content-width="800"
            v-if="client && client.orders"

            ref="orderPdf"
        >
            <order-bill slot="pdf-content" :account="client" :order="order"/>

        </vue-html2pdf>
    </div>
</template>

<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import OrderBill from "../pdf/OrderBill";
    import PriceOption from "../front/PriceOption";

    export default {
        name: "ModalClient",
        components: {OrderBill, VueHtml2pdf},
        data() {
            return {
                order: null,
            }
        },
        computed: {
            client() {
                return this.$parent.clientActif
            },
        },
        methods: {
            generateBill(order) {
                this.order=order
                this.$refs.orderPdf.generatePdf()
            },
        },
    }
</script>

<style scoped>

</style>
