var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mt-5" },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-md-center mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "10", md: "6" } },
            [
              _c("b-form-input", {
                attrs: { type: "email", placeholder: "email" },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "justify-content-md-center mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "10", md: "6" } },
            [
              _c("b-form-input", {
                attrs: { type: "password", placeholder: "password" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.signin($event)
                  }
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "justify-content-md-center mb-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "10", md: "6" } },
            [
              _c(
                "b-button",
                { attrs: { variant: "primary" }, on: { click: _vm.signin } },
                [_vm._v("sign in")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }