var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _vm.content_keys && _vm.element
        ? _c(
            "b-form-select",
            {
              model: {
                value: _vm.content_keys,
                callback: function($$v) {
                  _vm.content_keys = $$v
                },
                expression: "content_keys"
              }
            },
            _vm._l(_vm.content_keys, function(contentKey) {
              return _c(
                "b-form-select-option",
                { key: contentKey.id, attrs: { value: contentKey.name } },
                [_vm._v(_vm._s(contentKey.name))]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-3" } },
        [
          _vm._l(_vm.content_keys, function(contentKey) {
            return _vm.content_keys && _vm.element
              ? _c(
                  "b-tab",
                  {
                    key: contentKey.id,
                    attrs: { lazy: "", title: contentKey.name }
                  },
                  [
                    _c("content-key", {
                      attrs: {
                        contentKey: contentKey,
                        contentType: _vm.element,
                        "get-content-keys": _vm.getContentKeys,
                        "make-toast": _vm.makeToast
                      }
                    }),
                    _vm._v(" "),
                    _c("translations", {
                      attrs: {
                        contentKey: contentKey,
                        "make-toast": _vm.makeToast
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "add a key" } },
            [
              _c("content-key", {
                attrs: {
                  contentType: _vm.element,
                  "get-content-keys": _vm.getContentKeys,
                  "make-toast": _vm.makeToast
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }