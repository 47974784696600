var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account
    ? _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var handleSubmit = ref.handleSubmit
                return [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return handleSubmit(_vm.onSubmit)
                        }
                      }
                    },
                    [
                      _c(
                        "b-container",
                        { staticClass: "page" },
                        [
                          _c("b-row", { staticClass: "titre mb-2" }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("website.basepourundevis")
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "stitre font-weight-bold" },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "domaines." +
                                        _vm.example.topic.domain.title
                                    ) + " :"
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "ml-1",
                                domProps: {
                                  innerHTML: _vm._s(
                                    " " +
                                      _vm.$t(
                                        "domaines." + _vm.example.topic.title
                                      )
                                  )
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-row", { staticClass: "mb-2" }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("examples." + _vm.example.title)
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "mt-4 topic" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "font-weight-bold stitre ucfirst mt-2 mb-3",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm
                                          .$t("website.honorairescreation")
                                          .toLowerCase()
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.calcul.honoraires, function(
                                honoraire,
                                index
                              ) {
                                return _c(
                                  "b-col",
                                  { key: index, attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "10" } }, [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(1 * index + 1)
                                            }
                                          }),
                                          _vm._v(
                                            ".\n                            "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "ucfirst" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$t(
                                                      "website." +
                                                        _vm.honoraires[index]
                                                    )
                                                    .toLowerCase()
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "text-right",
                                            attrs: { cols: "2" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    honoraire.hour *
                                                      honoraire.rate
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.account.currency
                                                    ? _vm.account.currency
                                                        .symbol
                                                    : "€"
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "ucfirst mb-3",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm
                                          .$t("website.delainormal")
                                          .toLowerCase()
                                      ) +
                                      " " +
                                      _vm._s(_vm.delai) +
                                      " " +
                                      _vm._s(_vm.$t("website.jours")) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-2 font-weight-bold",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: "9" } }),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "ucfirst text-right",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm
                                                  .$t(
                                                    "website.totalcalculhorstaxe"
                                                  )
                                                  .toLowerCase()
                                              ) +
                                              " =\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  _vm.totalHonoraires
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.account.currency
                                                  ? _vm.account.currency.symbol
                                                  : "€"
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.calcul.exploitation
                            ? _c(
                                "b-row",
                                { staticClass: "mt-4 topic" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "font-weight-bold stitre ucfirst mt-2 mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm
                                              .$t("website.04_p01_titre")
                                              .toLowerCase()
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "ucfirst" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t("website.05_oeuvre_nonvendue")
                                              .toLowerCase()
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "ucfirst" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .$t("website.utilisation")
                                                .toLowerCase()
                                            ) + " :\n                        "
                                          ),
                                          _vm._l(
                                            _vm.calcul.exploitation,
                                            function(exploit, index) {
                                              return _c(
                                                "span",
                                                { key: index },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "cession." +
                                                              exploit.model
                                                                .title
                                                          )
                                                          .toLowerCase()
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                  index <
                                                  Object.keys(
                                                    _vm.calcul.exploitation
                                                  ).length -
                                                    1
                                                    ? _c("span", [_vm._v(", ")])
                                                    : _vm._e()
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "ucfirst",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t(
                                                "website.06red_reproduiterepresentee"
                                              )
                                              .toLowerCase()
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2 font-weight-bold",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { cols: "9" } }),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "ucfirst text-right",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm
                                                      .$t(
                                                        "website.totalcalculhorstaxe"
                                                      )
                                                      .toLowerCase()
                                                  ) +
                                                  " =\n                        "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      _vm.totalExploitation
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.account.currency
                                                      ? _vm.account.currency
                                                          .symbol
                                                      : "€"
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.calcul.redevance
                            ? _c(
                                "b-row",
                                { staticClass: "mt-4 topic" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "font-weight-bold stitre ucfirst mt-2 mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm
                                              .$t("website.04_p03_titre")
                                              .toLowerCase()
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "ucfirst" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t("website.06red_oeuvrevendue")
                                              .toLowerCase()
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ucfirst" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$t("website.utilisation")
                                              .toLowerCase()
                                          ) + " :\n                        "
                                        ),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "website." +
                                                  _vm.redevance[
                                                    _vm.calcul.redevance.rights
                                                  ]
                                              )
                                            )
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "ucfirst",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t(
                                                "website.06red_reproduiterepresentee"
                                              )
                                              .toLowerCase()
                                          )
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            {
                              staticClass: "mt-4 topic",
                              attrs: { "align-h": "end" }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "font-weight-bold stitre ucfirst mt-2 mb-3",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm
                                          .$t("website.06red_totaldevis")
                                          .toLowerCase()
                                      ) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("droit-total-cell", {
                                    attrs: {
                                      currency: _vm.account.currency
                                        ? _vm.account.currency.symbol
                                        : "€",
                                      total: _vm.total,
                                      account: _vm.account
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.calcul.option
                            ? _c(
                                "b-row",
                                { staticClass: "mt-4 topic" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "font-weight-bold stitre ucfirst mt-2 mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t("website.04_p02_option")
                                              .toLowerCase()
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "10" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "ucfirst" },
                                                [
                                                  _vm._v(
                                                    "\n                                " +
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "website.04_p02_cout"
                                                          )
                                                          .toLowerCase()
                                                      ) +
                                                      "\n                                " +
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "website.04_sur" +
                                                              _vm.calcul
                                                                .option2 +
                                                              "ans"
                                                          )
                                                          .toLowerCase()
                                                      ) +
                                                      " :\n                                + " +
                                                      _vm._s(
                                                        _vm.calcul.option[0]
                                                          .rate
                                                      ) +
                                                      "% " +
                                                      _vm._s(
                                                        _vm
                                                          .$t(
                                                            "website.05_surtotaldroits"
                                                          )
                                                          .toLowerCase()
                                                      ) +
                                                      "\n                            "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "text-right",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("formatNumber")(
                                                      _vm.calcul.option[0].value
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.account.currency
                                                      ? _vm.account.currency
                                                          .symbol
                                                      : "€"
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._l(2, function(i) {
                                        return _c(
                                          "b-row",
                                          { key: i },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "ucfirst" },
                                                  [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(
                                                          _vm.calcul.option[i]
                                                            .rate
                                                        ) +
                                                        "%  " +
                                                        _vm._s(
                                                          _vm
                                                            .$t(
                                                              "website.04_p02_annee" +
                                                                i
                                                            )
                                                            .toLowerCase()
                                                        ) +
                                                        "\n                            "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "text-right",
                                                attrs: { cols: "2" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm._f("formatNumber")(
                                                        _vm.calcul.option[i]
                                                          .value
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.account.currency
                                                        ? _vm.account.currency
                                                            .symbol
                                                        : "€"
                                                    ) +
                                                    "\n                        "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      _vm._v(" "),
                                      Object.keys(_vm.calcul.option).length > 2
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "10" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "ucfirst" },
                                                    [
                                                      _vm._v(
                                                        "\n                                " +
                                                          _vm._s(
                                                            _vm
                                                              .$t(
                                                                "website.04_p02_anneex"
                                                              )
                                                              .toLowerCase()
                                                          ) +
                                                          "\n                            "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(
                                                        _vm._f("formatNumber")(
                                                          _vm.calcul.option[3]
                                                            .value
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.account.currency
                                                          ? _vm.account.currency
                                                              .symbol
                                                          : "€"
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.calcul.option
                            ? _c(
                                "b-row",
                                {
                                  staticClass: "mt-4 topic",
                                  attrs: { "align-h": "end" }
                                },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "font-weight-bold stitre ucfirst mt-2",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t(
                                                "website.05_totaldevis_echelonnement"
                                              )
                                              .toLowerCase()
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("droit-total-cell", {
                                        attrs: {
                                          currency: _vm.account.currency
                                            ? _vm.account.currency.symbol
                                            : "€",
                                          total: _vm.totalOption,
                                          account: _vm.account
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.calcul.option
                            ? _c(
                                "b-row",
                                {
                                  staticClass: "mt-4 topic",
                                  attrs: { "align-h": "end" }
                                },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "font-weight-bold stitre ucfirst mt-2 mb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t(
                                                "website.05_totaldevis_1reannee_echelonnement"
                                              )
                                              .toLowerCase()
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "3" } },
                                    [
                                      _c("droit-total-cell", {
                                        attrs: {
                                          currency: _vm.account.currency
                                            ? _vm.account.currency.symbol
                                            : "€",
                                          total: _vm.totalOption1,
                                          account: _vm.account
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.calcul.exploitation
                            ? _c(
                                "b-row",
                                { staticClass: "mb-3 mt-2 font-weight-bold" },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "website.04_devis_prixsansdroits"
                                        )
                                      )
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("b-row", { staticClass: "mb-2" }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("website.textedevis"))
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          2201893560
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }