<template>
    <tab-input
        url="languages"
        title="Languages"
        add-text="add a language"
        :make-toast="makeToast"
        title-notification="Language Notification"
        name="Language"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Languages-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
