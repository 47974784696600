<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col class="text-nowrap pr-1 pt-2">{{ $t('website.' + title) }}</b-col>
                <b-col class="pr-0">
                    <b-input-group size="sm" append="%">
                        <b-form-select
                            size="sm"
                            v-model="rate"
                            @change="changeValue()"
                        >
                            <b-form-select-option
                                v-for="i in 19"
                                :key="i"
                                :value="i+1"
                            >
                                {{ i+1 }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="4" class="text-right pt-2">{{ value |formatNumber }} {{ currency }}</b-col>
    </b-row>
</template>

<script>
    import {serverBus} from "../../../../app";

    export default {
        name: "LineAvance",
        props: ['index', 'modelH', 'currency', 'name', 'total'],
        data() {
            return {
                rate: null,
            }
        },
        computed: {
            title() {
                return this.modelH[this.index]['key']
            },
            value() {
                return Math.round(this.rate*this.total/100)
            }
        },
        mounted() {
            this.initialValues()
            this.checkCalculsAvance()
        },
        methods: {
            initialValues() {
                this.rate = this.modelH[this.index]['rate']
            },
            checkCalculsAvance() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name]) {
                    this.rate = calcul[this.name]['rate']
                }
            },
            setAvance() {
                let calcul = this.$store.state.calcul
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                calcul[this.name]['rate'] = this.rate
                calcul[this.name]['value'] = this.value

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
            },
            changeValue() {
                this.setAvance()
            }
        },
    }
</script>

<style scoped>

</style>
