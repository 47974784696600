var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.rateCalcul
      ? _c("div", [
          _c("span", { class: _vm.hasBold ? "font-weight-bold" : "" }, [
            _vm._v(_vm._s(Math.round(_vm.rateCalcul)))
          ]),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.currency.symbol) } }),
          _vm._v(" "),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$t("website.03_tab_heure")) }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c("span", [_vm._v(_vm._s(Math.round(_vm.minCalcul)))]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.$t("website.a")) } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(Math.round(_vm.maxCalcul)))]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.currency.symbol) } })
    ]),
    _vm._v(" "),
    _c("div", [
      _c("span", { class: _vm.hasBold ? "font-weight-bold" : "" }, [
        _vm._v(_vm._s(Math.round(_vm.moyenneCalcul)))
      ]),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.currency.symbol) } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }