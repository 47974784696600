<template>
    <div class="blocGreenBottom">
        <b-container :class="alignButton ? alignButton : 'text-center'">
            <b-button
                variant="primary"
                v-html="buttonText"
                type="submit"
                v-if="buttonText"
                @click="setAction('buttonText')"
                class="mb-1 mt-1"
            />
            <b-button
                variant="primary"
                v-html="buttonText2"
                type="submit"
                v-if="buttonText2"
                @click="setAction('buttonText2')"
                class="mb-1 mt-md-1"
            />
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "BottomActions",
        props: ["buttonText", "buttonText2", "alignButton", "setAction"],
    }
</script>

<style scoped>

</style>
