var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    { attrs: { striped: "" } },
    [
      _c(
        "b-tbody",
        [
          _vm._l(3, function(i) {
            return _c("calcul-cell", {
              key: i,
              attrs: {
                index: i - 1,
                title:
                  i +
                  ". " +
                  _vm.$t("website." + _vm.honoraires[i - 1]["title"]),
                number: _vm.honoraires[i - 1]["number"],
                example: _vm.example,
                account: _vm.account,
                name: "honoraires"
              }
            })
          }),
          _vm._v(" "),
          _c(
            "b-tr",
            [
              _c("b-td", [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("website.03_p02_total"))
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "b-td",
                {
                  staticClass: "text-right font-weight-bold",
                  attrs: { colspan: "3" }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("formatNumber")(_vm.totalHonoraires)) +
                      " "
                  ),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.account.currency ? _vm.account.currency.symbol : "€"
                      )
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }