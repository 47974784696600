<template>
    <b-container>
        <b-row class="mt-5 mb-5" v-if="account">
            <b-col cols="8">
                <div class="titre mb-4" v-html="$t('tarifs.gestion_entreprise_titre')"></div>

                <div v-if="account.clients">
                    <div v-for="client in account.clients" :key="client.id">
                        <h6>
                            <b-badge variant="light" class="font-weight-light">
                                {{ client.firstname }} {{ client.lastname }} | <a href="#" @click="removeClient(client)">{{ $t('website.supprimer') }}</a>
                            </b-badge>
                        </h6>
                    </div>
                </div>
            </b-col>
            <b-col>
                <b-card
                    bg-variant="light"
                >
                    <b-card-title class="text-center">
                        <router-link :to="{name: 'account', params: {lng: $store.state.actLng }}">
                            <h6>{{ account.firstname }} {{ account.lastname }}<br>{{ account.society }}</h6>
                        </router-link>
                        <hr/>
                    </b-card-title>
                    <b-card-text>
                        <div class="text-center small">
                            {{ account.phone }}<br>
                            {{ account.address }}<br>
                            {{ account.zipcode }} {{ account.city }}
                        </div>
                        <hr/>
                        <b-list-group>
                            <b-list-group-item v-for="order in account.orders" class="small" :key="order.id">
                                Commande N°{{ order.id }}
                                <div class="text-primary" v-html="$t('tarifs.abonnementannuel')"/>
                                <span v-html="$t('home.jusquau')"/> {{ order.validationDate | moment('DD/MM/YYYY') }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-text>
                </b-card>

                <hr/>

                <b-button size="sm" variant="primary" v-html="$t('website.ajouter_utilisateur')" @click="addClient"/>
            </b-col>
        </b-row>
        <modal-subscription :get-account="getAccount" :account="account"/>
    </b-container>
</template>

<script>
    import axios from "axios";
    import ModalSubscription from "../../../components/form/ModalSubscription";

    export default {
        name: "Enterprise",
        components: {ModalSubscription},
        data() {
            return {
                account: null,
            }
        },
        created() {
            this.getAccount()
        },
        methods: {
            addClient() {
                this.$bvModal.show('modalSubscription')
            },
            removeClient(client) {
                this.$bvModal.msgBoxConfirm('Are you sure?').then(
                    value => {
                        if(value) {
                            client.enterprise = null
                            const url = this.$store.state.apiUrl + client["@id"]
                            axios.patch(
                                url,
                                client,
                                {
                                    'headers': {
                                        'Content-Type': this.$store.state.patchHeader,
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getAccount()
                                }
                            )
                        }
                    }
                )
            },
            getAccount() {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
