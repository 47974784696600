<template>
    <tab-input
        url="prices"
        title="Prices"
        add-text="add a price"
        :make-toast="makeToast"
        title-notification="Price Notification"
        name="Price"
        generate-file="prices"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Prices-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
