<template>
    <b-container class="page">
        <b-row class="titre mb-4">
            <div v-html="$t('website.01_page_titre')"/>
        </b-row>
        <b-row v-for="domain in domains" :key="domain.id" class="topic" v-if="domain.menu">
            <b-col sm="12" md="3" class="titre">
                <router-link :to="{ name: 'domaine', params: {slug: domain.title, id: domain.id, lng: lng}}" v-html="$t('menu.' + domain.title)"/>
            </b-col>
            <b-col>
                <b-row>
                    <b-col sm="12" md="4" v-for="topic in domain.topics" :key="topic.id" class="mb-3">
                        <router-link
                            :to="{ name: 'domaine', params: {slug: domain.title, id: domain.id, lng: lng}}"
                            v-html="'&bull; '+$t('domaines.' + topic.title)"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import domainsJson from '../../../../files/domains.json'
    import axios from "axios";
    import {serverBus} from "../../../../app";

    export default {
        name: "Discipline",
        data() {
            return {
                //domains: domainsJson,
                domains: null,
                //topics: null,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
        },
        mounted() {
            this.getDomains()
        },
        methods: {
            getDomains() {
                this.$store.commit("setDomainStep", 1)
                this.$store.commit("setDomains", true)

                const url = this.$store.state.apiUrl + '/api/front/domains'
                axios.get(
                    url
                ).then(
                    response => {
                        this.domains = response.data["hydra:member"]
                    }
                )
            }
        }
    }
</script>

<style scoped>
</style>
