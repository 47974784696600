var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        { attrs: { id: "modalClient" } },
        [
          _vm.client
            ? _c(
                "b-card",
                {
                  attrs: {
                    header: _vm.client.firstname + " " + _vm.client.lastname
                  }
                },
                [
                  _c(
                    "b-card-text",
                    [
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "10" } }, [
                              _c("div", { staticClass: "mb-2" }, [
                                _vm.client.society
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.client.society))
                                    ])
                                  : _vm._e(),
                                _vm._v(" ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("b-icon-telephone-fill", {
                                    staticClass: "mb-1",
                                    attrs: { variant: "info" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.client.phone))
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("b-icon-signpost", {
                                    attrs: { variant: "info" }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.client.address) +
                                      " " +
                                      _vm._s(_vm.client.zipcode) +
                                      "\n                                " +
                                      _vm._s(_vm.client.city) +
                                      " "
                                  ),
                                  _vm.client.country
                                    ? _c("span", [
                                        _vm._v(
                                          "- " +
                                            _vm._s(
                                              _vm.$t(
                                                "countries." +
                                                  _vm.client.country.code
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("b-col", [
                              _vm.client.keycloakId
                                ? _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c("b-icon-lock-fill", {
                                        attrs: { variant: "primary" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c("b-col", [
                              _c("div", {
                                staticClass: "font-weight-bold",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("website.00b_profil")
                                  )
                                }
                              })
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _vm.client.countryProfil
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "countries." +
                                            _vm.client.countryProfil.code
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("b-col", [
                              _vm.client.currency
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "devises." +
                                            _vm.client.currency.code.toLowerCase()
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _vm.client.hourRate
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("website.tauxpersonnalise") +
                                          " " +
                                          _vm.client.hourRate
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.client.vatRate
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("website.tvapersonnalise") +
                                          " " +
                                          _vm.client.vatRate +
                                          "%"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("website.enquete") +
                                      " : " +
                                      (_vm.client.ckFigure
                                        ? _vm.$t("website.oui")
                                        : _vm.$t("website.non"))
                                  )
                                }
                              })
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.client.profil
                          ? _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("website.00b_profildesigner") +
                                          " : " +
                                          _vm.$t(
                                            "website." + _vm.client.profil.name
                                          )
                                      )
                                    }
                                  })
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.client.activity
                          ? _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("website.00b_activite") +
                                          " : " +
                                          _vm.$t(
                                            "website." +
                                              _vm.client.activity.name
                                          )
                                      )
                                    }
                                  })
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-list-group",
                            _vm._l(_vm.client.orders, function(order) {
                              return _c(
                                "b-list-group-item",
                                {
                                  key: order.id,
                                  staticClass:
                                    "d-flex justify-content-between align-items-center",
                                  attrs: {
                                    variant: order.active
                                      ? "success"
                                      : order.paymentDate
                                      ? ""
                                      : "danger"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "\n                                    Commande n°" +
                                          _vm._s(order.id) +
                                          " - " +
                                          _vm._s(order.amount) +
                                          "€\n                                    "
                                      ),
                                      order.subscriptionType &&
                                      order.paymentDate
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                        (" +
                                                _vm._s(
                                                  order.subscriptionType.name
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    order.paymentDate,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) +
                                                ")\n                                    "
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "h6",
                                        [
                                          _c("b-badge", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("moment")(
                                                  order.creationDate,
                                                  "DD/MM/YYYY"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    order.price
                                      ? _c("div", [
                                          !order.example
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-primary priceName"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(order.price.name)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _c("div", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "domaines." +
                                                    order.example.topic.domain
                                                      .title
                                                )
                                              )
                                            }
                                          }),
                                          _vm._v(
                                            " /\n                                    "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "domaines." +
                                                    order.example.topic.title
                                                )
                                              )
                                            }
                                          }),
                                          _vm._v(
                                            " /\n                                    "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "examples." +
                                                    order.example.title
                                                )
                                              )
                                            }
                                          })
                                        ]),
                                    _vm._v(" "),
                                    order.paymentDate
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                    => " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  order.validationDate,
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  order.paymentDate
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.generateBill(order)
                                            }
                                          }
                                        },
                                        [
                                          order.paymentDate
                                            ? _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    pill: ""
                                                  }
                                                },
                                                [
                                                  _c("b-icon-download", {
                                                    staticClass: "mt-1 mb-1",
                                                    attrs: { "font-scale": "2" }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.client && _vm.client.orders
        ? _c(
            "vue-html2pdf",
            {
              ref: "orderPdf",
              attrs: {
                "show-layout": false,
                "enable-download": false,
                "preview-modal": true,
                "paginate-elements-by-height": 1400,
                filename: "confirmation",
                "pdf-quality": 2,
                "manual-pagination": false,
                "pdf-format": "a4",
                "pdf-content-width": 800
              }
            },
            [
              _c("order-bill", {
                attrs: {
                  slot: "pdf-content",
                  account: _vm.client,
                  order: _vm.order
                },
                slot: "pdf-content"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }