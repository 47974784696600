<template>
    <div>
        <div v-if="rateCalcul">
            <span :class="hasBold ? 'font-weight-bold' : ''">{{ Math.round(rateCalcul) }}</span>
            <span v-html="currency.symbol"/>
            <span v-html="$t('website.03_tab_heure')"/>
        </div>
        <div>
            <span>{{ Math.round(minCalcul) }}</span>
            <span v-html="$t('website.a')"/>
            <span>{{ Math.round(maxCalcul) }}</span>
            <span v-html="currency.symbol"/>
        </div>
        <div>
            <span :class="hasBold ? 'font-weight-bold' : ''">{{ Math.round(moyenneCalcul) }}</span>
            <span v-html="currency.symbol"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RateCell",
        props: ['rate', 'group', 'currency', 'example', 'hasBold'],
        computed: {
            rateCalcul() {
                return this.rate*this.group.coef*this.currency.rate
            },
            minCalcul() {
                return this.rateCalcul*this.example.minDelay
            },
            maxCalcul() {
                return this.rateCalcul*this.example.maxDelay
            },
            moyenneCalcul() {
                return (this.minCalcul + this.maxCalcul)/2
            }
        },
    }
</script>

<style scoped>

</style>
