<template>
    <tab-input
        url="partners"
        title="Partners"
        add-text="add a partner"
        :make-toast="makeToast"
        title-notification="Partner Notification"
        name="Partner"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Partners-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>

</style>
