var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "profils",
      title: "Profils types",
      "add-text": "add a profil type",
      "make-toast": _vm.makeToast,
      "title-notification": "Profil Notification",
      "generate-file": "profilstypes"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }