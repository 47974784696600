var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mt-3" },
    _vm._l(_vm.languages, function(language) {
      return _c(
        "b-row",
        { key: language.id },
        [
          _c("translation", {
            attrs: {
              language: language,
              "content-key": _vm.contentKey,
              "make-toast": _vm.makeToast
            }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }