var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-admin"),
      _vm._v(" "),
      _vm.page
        ? _c(_vm.page, {
            tag: "component",
            attrs: { name: "page", "make-toast": _vm.makeToast }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.pseudo ? _c("login") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }