var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var handleSubmit = ref.handleSubmit
                return [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return handleSubmit(_vm.onSubmit)
                        }
                      }
                    },
                    [
                      _vm.example && _vm.account
                        ? _c(
                            "b-container",
                            { staticClass: "page" },
                            [
                              _c("b-row", { staticClass: "titre mb-2" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "domaines." +
                                          _vm.example.topic.domain.title
                                      )
                                    )
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "stitre font-weight-bold" },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "domaines." + _vm.example.topic.title
                                        )
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-row", { staticClass: "mb-2" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("examples." + _vm.example.title)
                                    )
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "calculLine",
                                      attrs: { sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c("div", {
                                              staticClass: "font-weight-bold",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "website.totalhonoraires"
                                                  )
                                                )
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "b-col",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatNumber")(
                                                    _vm.totalHonoraires
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.account.currency
                                                      ? _vm.account.currency
                                                          .symbol
                                                      : "€"
                                                  )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("droit-exploitation", {
                                attrs: {
                                  "total-honoraires": _vm.totalHonoraires,
                                  name: "exploitation"
                                }
                              }),
                              _vm._v(" "),
                              _c("droit-option", { attrs: { name: "option" } }),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { staticClass: "pl-0 pr-0" }, [
                                    _c("hr")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.account &&
                              _vm.account.lastOrder &&
                              _vm.account.lastOrder.price &&
                              _vm.account.lastOrder.price.name === "free"
                                ? _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c("span", {
                                          staticClass: "text-danger",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "website.04_p03_id_redevance"
                                              )
                                            )
                                          }
                                        })
                                      ])
                                    ],
                                    1
                                  )
                                : _c("droit-redevance", {
                                    attrs: { name: "redevance" }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.account &&
                      _vm.account.lastOrder &&
                      _vm.account.lastOrder.price &&
                      _vm.account.lastOrder.price.name === "free"
                        ? _c("bottom-actions", {
                            attrs: {
                              "button-text": _vm.$t(
                                "website.04_p02_bt_devisforfait"
                              ),
                              "set-action": _vm.setAction
                            }
                          })
                        : _c("bottom-actions", {
                            attrs: {
                              "button-text": _vm.$t(
                                "website.04_p02_bt_devisforfait"
                              ),
                              "button-text2": _vm.$t(
                                "website.04_p03_bt_devisredevance"
                              ),
                              "set-action": _vm.setAction
                            }
                          })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          2995209731
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }