var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "h5",
        [
          _c("b-badge", { attrs: { variant: "primary" } }, [
            _vm._v(_vm._s(_vm.name))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.name
        ? _c(_vm.name, {
            tag: "component",
            attrs: { name: "name", "make-toast": _vm.makeToast }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }