var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _vm.loader
                ? _c(
                    "b-button",
                    { attrs: { variant: "primary", disabled: "" } },
                    [
                      _c("b-spinner", { attrs: { small: "", type: "grow" } }),
                      _vm._v("\n                Loading...\n            ")
                    ],
                    1
                  )
                : _vm.user.roles.includes("super_admin")
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "sm" },
                      on: { click: _vm.deleteKeycloakClients }
                    },
                    [
                      _vm._v(
                        "\n                delete keycloak users\n            "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.clients
            ? _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "align-content-center" },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.rows,
                              "per-page": _vm.perPage,
                              "last-number": "",
                              "aria-controls": "my-table"
                            },
                            on: { change: _vm.goPage },
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "b-row",
                            _vm._l(_vm.prices, function(pr) {
                              return _c(
                                "b-col",
                                { key: pr.id, attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        switch: "",
                                        size: "sm",
                                        value: pr.name
                                      },
                                      on: { change: _vm.getClients },
                                      model: {
                                        value: _vm.price,
                                        callback: function($$v) {
                                          _vm.price = $$v
                                        },
                                        expression: "price"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(pr.name) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("b-input", {
                            attrs: {
                              type: "search",
                              size: "sm",
                              placeholder: "search (email-firstname-lastname)"
                            },
                            on: { input: _vm.searchClients },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "1" } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { switch: "", size: "sm" },
                                on: { change: _vm.getClients },
                                model: {
                                  value: _vm.actif,
                                  callback: function($$v) {
                                    _vm.actif = $$v
                                  },
                                  expression: "actif"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            actif\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { switch: "", size: "sm" },
                                on: { change: _vm.getClients },
                                model: {
                                  value: _vm.unsecure,
                                  callback: function($$v) {
                                    _vm.unsecure = $$v
                                  },
                                  expression: "unsecure"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            unsecure\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.clients
        ? _c(
            "b-badge",
            { attrs: { variant: _vm.actif ? "success" : "danger" } },
            [_vm._v(_vm._s(_vm.rows))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.clients
        ? _c(
            "b-card-group",
            { attrs: { columns: "" } },
            _vm._l(_vm.clients, function(client) {
              return _c(
                "b-card",
                {
                  key: client.id,
                  attrs: { header: client.firstname + " " + client.lastname }
                },
                [
                  _c("client-card", {
                    attrs: {
                      client: client,
                      "check-keycloak": _vm.checkKeycloak,
                      "get-role": _vm.getRole
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("modal-client")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }