var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "h5",
        [
          _c("b-badge", { attrs: { variant: "primary" } }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.elements
        ? _c(
            "b-button",
            { attrs: { variant: "primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _vm._v("\n        Loading...\n    ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.elements
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3" } },
                    [
                      _vm._l(_vm.elements, function(element) {
                        return _c(
                          "b-tab",
                          {
                            key: element.id,
                            attrs: {
                              lazy: "",
                              title: element.email
                                ? element.email
                                : element.code
                                ? element.code
                                : element.title
                                ? element.title
                                : element.name,
                              "title-link-class": element.state
                                ? "success"
                                : "danger"
                            }
                          },
                          [
                            _vm.name
                              ? _c(_vm.name, {
                                  tag: "component",
                                  attrs: {
                                    element: element,
                                    url: _vm.url,
                                    "get-elements": _vm.getElements,
                                    "make-toast": _vm.makeToast,
                                    "title-notification": _vm.titleNotification
                                  }
                                })
                              : _c("name-state", {
                                  attrs: {
                                    element: element,
                                    url: _vm.url,
                                    "get-elements": _vm.getElements,
                                    "make-toast": _vm.makeToast,
                                    "title-notification": _vm.titleNotification
                                  }
                                }),
                            _vm._v(" "),
                            _vm.name2
                              ? _c(_vm.name2, {
                                  tag: "component",
                                  attrs: {
                                    element: element,
                                    url: _vm.url,
                                    "get-elements": _vm.getElements,
                                    "make-toast": _vm.makeToast,
                                    "title-notification": _vm.titleNotification
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.addText } },
                        [
                          _vm.name
                            ? _c(_vm.name, {
                                tag: "component",
                                attrs: {
                                  priority: _vm.elements
                                    ? _vm.elements.length
                                    : 1,
                                  url: _vm.url,
                                  "title-notification": _vm.titleNotification,
                                  "get-elements": _vm.getElements,
                                  "make-toast": _vm.makeToast
                                }
                              })
                            : _c("name-state", {
                                attrs: {
                                  priority: _vm.elements
                                    ? _vm.elements.length
                                    : 1,
                                  url: _vm.url,
                                  "title-notification": _vm.titleNotification,
                                  "get-elements": _vm.getElements,
                                  "make-toast": _vm.makeToast
                                }
                              })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }