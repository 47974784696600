var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        { attrs: { id: "payment-form" } },
        [
          _c(
            "b-container",
            { staticClass: "mb-5" },
            [
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c("b-col", { staticClass: "titre mb-2" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.sabonnera"))
                      }
                    }),
                    _vm._v(" "),
                    _vm.exemple
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t(
                                  "domaines." + _vm.exemple.topic.domain.title
                                )
                              ) +
                              " /\n                    " +
                              _vm._s(
                                _vm.$t("domaines." + _vm.exemple.topic.title)
                              ) +
                              " /\n                    "
                          ),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("examples." + _vm.exemple.title)
                              )
                            }
                          })
                        ])
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.displayUser
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("tarifs.soldearegler")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("tarifs.horstva")
                                      )
                                    }
                                  }),
                                  _vm._v(" =\n                      ")
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "tarif" }, [
                                  _vm._v(_vm._s(_vm.amount) + " "),
                                  _c("span", { staticClass: "devise" }, [
                                    _vm._v("€")
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "font-weight-bold mt-5" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.$t("website.tva"))
                                      }
                                    }),
                                    _vm._v(" = " + _vm._s(_vm.tva) + " €")
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "font-weight-bold mb-3" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("tarifs.totalttcpaiement")
                                        )
                                      }
                                    }),
                                    _vm._v(" = " + _vm._s(_vm.total) + " "),
                                    _c("sup", [_vm._v("€")])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.amount && _vm.amount > 0
                        ? _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _vm.card && !_vm.loading
                                ? _c("label", { staticClass: "titre" }, [
                                    _vm._v("Paiement")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { attrs: { id: "card-element" } }),
                              _vm._v(" "),
                              _vm.card && !_vm.loading
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        size: "sm",
                                        variant: "primary",
                                        id: "card-button"
                                      },
                                      on: { click: _vm.submitPayment }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("website.payer")) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.loading ? _c("Loading") : _vm._e(),
                              _vm._v(" "),
                              _c("div", { attrs: { id: "payment-result" } }),
                              _vm._v(" "),
                              _vm.errorMessage
                                ? _c(
                                    "b-alert",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { variant: "danger", show: "" }
                                    },
                                    [_vm._v(_vm._s(_vm.errorMessage))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("hr")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }