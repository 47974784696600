import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=ce284ae2&scoped=true&"
import script from "./Clients.vue?vue&type=script&lang=js&"
export * from "./Clients.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce284ae2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/wwwroot/calkulator/site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ce284ae2')) {
      api.createRecord('ce284ae2', component.options)
    } else {
      api.reload('ce284ae2', component.options)
    }
    module.hot.accept("./Clients.vue?vue&type=template&id=ce284ae2&scoped=true&", function () {
      api.rerender('ce284ae2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/vue/pages/admin/users/Clients.vue"
export default component.exports