var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "prices",
      title: "Prices",
      "add-text": "add a price",
      "make-toast": _vm.makeToast,
      "title-notification": "Price Notification",
      name: "Price",
      "generate-file": "prices"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }