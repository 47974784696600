<template>
    <tab-input
        url="options"
        title="Options"
        add-text="add an option"
        :make-toast="makeToast"
        title-notification="Option Notification"
        name="Option"
        generate-file="options"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Options-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
