<template>
    <b-container>
        <b-row v-if="user" class="mt-5 mb-5">
            <b-col cols="12">
                <div class="titre mb-2" v-html="$t('website.page_welcome_titre')"></div>
                <div v-html="$t('website.page_welcome_confirmation_free')" v-if="price && price.name === 'free'"/>
                <div v-html="$t('website.page_welcome_confirmation')" v-else/>


<!--                <div class="titre mb-2" v-html="$t('website.page_upgrade_titre')"></div>
                <div v-html="$t('website.page_upgrade_confirmation')"/>-->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Confirmation",
        computed: {
            user() {
                return this.$store.state.currentUser
            },
            price() {
                return this.$store.state.currentPrice
            },
        },
    }
</script>

<style scoped>

</style>
