<template>
    <b-row class="mt-3" v-if="account && totalExploitation>0">
        <b-col sm="12" md="6" class="mb-3">
            <b-row>
                <b-col class="pl-0">
                    <b-form-checkbox
                        v-model="hasOption"
                        @change="changeOption"
                    >
                        <div class="font-weight-bold pt-1" v-html="$t('website.04_p02_option')"/>
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-col>
        <b-col sm="12" md="6"/>
        <b-col sm="12" md="6" v-if="hasOption">
            <droit-cell
                :index="i-1"
                :name="name"
                :model-h="models[i-1]"
                :currency="account.currency.symbol"
                v-for="i in 4" :key="i"
                type="LineOption"
                :total="totalExploitation"
            />
            <droit-total-cell
                :currency="account.currency.symbol"
                :total="totalOption"
                :account="account"
            />
        </b-col>
    </b-row>
</template>

<script>
    import DroitCell from "./DroitCell";
    import DroitTotalCell from "./DroitTotalCell";
    import {serverBus} from "../../../../app";

    export default {
        name: "Option",
        components: {DroitTotalCell, DroitCell},
        props: ['name'],
        data() {
            return {
                account: null,
                models: [
                    {key: '04_p02_cout', option: [5,7,9,15], option2: [2, 3, 4, 5]},
                    {key: '04_p02_annee1', option: [50, 33, 25]},
                    {key: '04_p02_annee2', option: [50, 33, 25]},
                    {key: '04_p02_anneex'}
                ],
                hasOption: false,
                totalExploitation: 0,
                totalOption: 0,
            }
        },
        mounted() {
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                }
            )
            this.getCalculsExploitation()
            this.getCalculsOption()
            this.checkOption()
            serverBus.$on('exploitation', (isChanged) => {
                if(isChanged) {
                    this.getCalculsExploitation()
                    serverBus.$emit('exploitation', false)
                }
            })
            serverBus.$on('option', (isChanged) => {
                if(isChanged) {
                    this.getCalculsOption()
                    serverBus.$emit('option', false)
                }
            })
        },
        methods: {
            getCalculsExploitation() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul['exploitation']) {
                    this.totalExploitation = 0
                    Object.keys(calcul['exploitation']).forEach((key, index) => {
                        const calculObject = calcul['exploitation'][index]
                        if(calculObject['value']) {
                            this.totalExploitation += calculObject['value']
                        }
                    })
                }
            },
            getCalculsOption() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul['option'] && calcul['option'][0]) {
                    this.totalOption = this.totalExploitation + calcul['option'][0]['value']
                }
            },
            checkOption() {
                let calcul = this.$store.state.calcul
                this.hasOption = calcul['option'] ? true : false
            },
            changeOption() {
                let calcul = this.$store.state.calcul
                calcul['option'] = (this.hasOption) ? {} : null
                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
            },
        }
    }
</script>

<style scoped>

</style>
