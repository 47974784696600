var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _vm.domains
                ? _c(
                    "b-form-select",
                    {
                      staticClass: "mt-3",
                      attrs: { size: "sm" },
                      on: { change: _vm.selectDomain },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v("sort by domain")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.domains, function(domain) {
                        return _c(
                          "b-form-select-option",
                          {
                            key: domain.id,
                            style: "color: " + domain.color,
                            attrs: { value: domain["@id"] }
                          },
                          [_vm._v(_vm._s(_vm.$t("domaines." + domain.title)))]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _vm.topics && _vm.selected
                ? _c(
                    "b-form-select",
                    {
                      staticClass: "mt-3",
                      attrs: { size: "sm" },
                      on: { change: _vm.selectTopic },
                      model: {
                        value: _vm.selected2,
                        callback: function($$v) {
                          _vm.selected2 = $$v
                        },
                        expression: "selected2"
                      }
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v("sort by topic")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.topics, function(topic) {
                        return _c(
                          "b-form-select-option",
                          { key: topic.id, attrs: { value: topic["@id"] } },
                          [_vm._v(_vm._s(_vm.$t("domaines." + topic.title)))]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selected && _vm.selected2 && !_vm.examples
        ? _c(
            "b-button",
            { attrs: { variant: "primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _vm._v("\n            Loading...\n        ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        _vm._l(_vm.examples, function(example, index) {
          return _vm.selected &&
            _vm.selected2 &&
            _vm.examples &&
            _vm.selected === example.topic.domain["@id"] &&
            _vm.selected2 === example.topic["@id"]
            ? _c(
                "b-col",
                {
                  key: example.id,
                  staticClass: "mb-2 mt-2",
                  attrs: { cols: "12", sm: "12", md: "6", lg: "2" }
                },
                [
                  _c("validation-observer", {
                    ref: "observer",
                    refInFor: true,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      handleSubmit(_vm.setExample(example))
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      attrs: {
                                        "bg-variant": example.isFree
                                          ? "danger light"
                                          : "",
                                        "text-variant": example.isFree
                                          ? "white"
                                          : ""
                                      }
                                    },
                                    [
                                      _c("b-card-sub-title", {
                                        attrs: {
                                          "sub-title-text-variant": example.isFree
                                            ? "white"
                                            : ""
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("examples." + example.title)
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-card-text",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-2" },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mr-0 pt-1",
                                                  attrs: { cols: "4" }
                                                },
                                                [_vm._v("Minimum delay ")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  example
                                                    ? _c("b-form-input", {
                                                        attrs: {
                                                          size: "sm",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            example.minDelay,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              example,
                                                              "minDelay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "example.minDelay"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mr-0 pt-1",
                                                  attrs: { cols: "4" }
                                                },
                                                [_vm._v("Maximum delay ")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pl-0",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  example
                                                    ? _c("b-form-input", {
                                                        attrs: {
                                                          size: "sm",
                                                          type: "number"
                                                        },
                                                        model: {
                                                          value:
                                                            example.maxDelay,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              example,
                                                              "maxDelay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "example.maxDelay"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                [
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "4" }
                                                        },
                                                        [_vm._v("is free ?")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          example
                                                            ? _c("b-icon", {
                                                                attrs: {
                                                                  icon: example.isFree
                                                                    ? "bookmark-check"
                                                                    : "bookmark-x",
                                                                  "font-scale":
                                                                    "2",
                                                                  variant: example.isFree
                                                                    ? "white"
                                                                    : "danger"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.toggle(
                                                                      "isFree",
                                                                      example
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    example.isFree,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      example,
                                                                      "isFree",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "example.isFree"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "4" }
                                                        },
                                                        [_vm._v("is online ?")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "2" }
                                                        },
                                                        [
                                                          example
                                                            ? _c("b-icon", {
                                                                attrs: {
                                                                  icon: example.state
                                                                    ? "bookmark-check"
                                                                    : "bookmark-x",
                                                                  "font-scale":
                                                                    "2",
                                                                  variant: example.state
                                                                    ? "success"
                                                                    : "danger"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.toggle(
                                                                      "state",
                                                                      example
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    example.state,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      example,
                                                                      "state",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "example.state"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { size: "sm" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                model: {
                                                  value: example.title,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      example,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "example.title"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "primary",
                                                        type: "submit"
                                                      }
                                                    },
                                                    [_vm._v("save")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant: "danger",
                                                        type: "submit"
                                                      }
                                                    },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            : _vm._e()
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }