var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "col-sm-12 col-md-6 col-lg-4" },
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [_vm._v("Title ")]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _vm.contentKey
                        ? _c("b-form-input", {
                            attrs: { size: "sm", maxlength: "30" },
                            model: {
                              value: _vm.contentKey.name,
                              callback: function($$v) {
                                _vm.$set(_vm.contentKey, "name", $$v)
                              },
                              expression: "contentKey.name"
                            }
                          })
                        : _c("b-form-input", {
                            attrs: { size: "sm", maxlength: "30" },
                            model: {
                              value: _vm.newContentKey.name,
                              callback: function($$v) {
                                _vm.$set(_vm.newContentKey, "name", $$v)
                              },
                              expression: "newContentKey.name"
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-1" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.contentKey
                                ? _vm.save(_vm.contentKey)
                                : _vm.create()
                            }
                          }
                        },
                        [_vm._v("save")]
                      ),
                      _vm._v(" "),
                      _vm.contentKey
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.del(_vm.contentKey)
                                }
                              }
                            },
                            [_vm._v("delete")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }