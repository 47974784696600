var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "countries",
      title: "Countries",
      "add-text": "add a country",
      "make-toast": _vm.makeToast,
      "title-notification": "Country Notification",
      name: "Country",
      "generate-file": "countries"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }