<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col class="text-nowrap pr-1" :class="modelH.option ? 'pt-2' : ''">{{ $t('website.' + modelH.key) }}</b-col>
                <b-col class="pl-0" v-if="modelH.option">
                    <b-input-group size="sm" append="%">
                        <b-form-select
                            v-model="option"
                            @change="changeRate(index)"
                        >
                            <b-form-select-option
                                v-for="option in modelH.option"
                                :key="option.id"
                                :value="option"
                            >
                                {{ option }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-col>
                <b-col class="pl-0" cols="4" v-if="modelH.option2">
                    <b-input-group size="sm" :prepend="$t('cession.sur')" :append="$t('cession.ans')">
                        <b-form-select
                            size="sm"
                            v-model="option2"
                            @change="changeDuration()"
                        >
                            <b-form-select-option
                                v-for="option in modelH.option2"
                                :key="option.id"
                                :value="option"
                            >
                                {{ option }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="3" class="text-right pt-2">{{ val |formatNumber }} {{ currency }}</b-col>
    </b-row>
</template>

<script>
    import {serverBus} from "../../../../app";

    export default {
        name: "LineOption",
        props: ['index', 'modelH', 'currency', 'name', 'total'],
        data() {
            return {
                option: null,
                option2: null,
                val: 0,
            }
        },
        mounted() {
            this.checkCalculsOption()
            serverBus.$on('option', (isChanged) => {
                if(isChanged) {
                    this.checkCalculsOption()
                    serverBus.$emit('option', false)
                }
            })
        },
        methods: {
            checkCalculsOption() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name] && calcul[this.name][this.index]) {
                    const calculObject = calcul[this.name][this.index]
                    if(calculObject['value'] >= 0) {
                        this.val = calculObject['value']
                        this.option = calculObject['rate']
                    }
                }
                if(calcul && calcul['option2']) {
                    this.option2 = calcul['option2']
                }
            },
            changeRate() {
                if(this.index === 0) {
                    this.val = Math.round(this.option * this.total / 100)
                }

                let calcul = this.$store.state.calcul
                if(this.index > 0 && this.index < 3) {
                    this.val = Math.round(this.option * (this.total + calcul[this.name][0]['value'])/100)
                }

                this.setOption()
                this.setLast()
            },
            setOption() {
                let calcul = this.$store.state.calcul
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                if(!calcul[this.name][this.index]) {
                    calcul[this.name][this.index] = {}
                }
                calcul[this.name][this.index]['rate'] = this.option
                calcul[this.name][this.index]['value'] = this.val

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
            },
            changeDuration() {
                let calcul = this.$store.state.calcul
                calcul['option2'] = this.option2

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                this.setLast()
            },
            setLast() {
                let calcul = this.$store.state.calcul
                const lastIndex = 3
                if(calcul['option2'] && calcul[this.name][0] && calcul[this.name][1] && calcul[this.name][2]) {
                    if(!calcul[this.name][lastIndex]) {
                        calcul[this.name][lastIndex] = {}
                    }
                    calcul[this.name][lastIndex]['value'] = 0
                    if(calcul['option2'] > 2) {
                        calcul[this.name][lastIndex]['value'] = Math.round(100*(
                            this.total +
                            calcul[this.name][0]['value'] -
                            calcul[this.name][1]['value']  -
                            calcul[this.name][2]['value']
                        )/(calcul['option2']-2))/100
                    }
                    this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                    serverBus.$emit('option', true)
                }
            },
        },
    }
</script>

<style scoped>

</style>
