var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "text-center mt-3 mb-3 text-primary" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "mentions",
              params: { lng: _vm.lng, slug: "mentions_et_conditions" }
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("website.mentions")))]
      ),
      _vm._v(" |\n    "),
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "preambule",
              params: { lng: _vm.lng, slug: "preambule" }
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("website.preambule")))]
      ),
      _vm._v(" |\n    "),
      _c(
        "router-link",
        {
          attrs: {
            to: { name: "aide", params: { lng: _vm.lng, slug: "aide" } }
          }
        },
        [_vm._v(_vm._s(_vm.$t("website.aide")))]
      ),
      _vm._v("  |\n    "),
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("website.contact")) }
      }),
      _vm._v(" |\n    © 2008-" + _vm._s(_vm.year) + " "),
      _c(
        "a",
        { attrs: { href: "http://www.moneydesign.org/", target: "_blank" } },
        [_vm._v("Moneydesign")]
      ),
      _vm._v(" |\n    "),
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("home.cooperationafd")) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }