<template>
    <table class="col-sm-12 tarifs">
        <tbody>
        <tr v-for="(option, index) in options" v-if="option.state">
            <td class="col-sm-12">
                <b-row class="mb-1" :class="option.color">
                    <b-col cols="2">
                        <i :class="'icon-ICO'+option.icon"/>
                    </b-col>
                    <b-col class="text-left h-100"><span v-html="$t('tarifs.'+option.name)"/></b-col>
                    <b-col cols="3" class="text-center" v-if="priceOptions && priceOptions[price.id+'_'+option.id]">
                        <span
                            v-if="option.type === 'name'"
                            v-html="$t('tarifs.' + priceOptions[price.id+'_'+option.id]['value'])"
                            :class="(option.isBold) ? 'font-weight-bold' : ''"
                        />
                        <span v-else-if="option.type === 'state'">
                            <span v-if="priceOptions[price.id+'_'+option.id]['value']">
                                <i class="icon-ICOcheck"></i>
                            </span>
                            <span v-else>-</span>
                        </span>
                        <span v-else :class="(option.isBold) ? 'font-weight-bold' : ''">
                            {{ priceOptions[price.id+'_'+option.id]['value'] }}
                            <span v-if="option.type === 'amount'"> €</span>
                        </span>
                        <div
                            v-if="option.type === 'name' && domainSelected"
                            class="font-weight-bold text-danger"
                            v-html="$t('domaines.'+domainSelected.title)"
                        />
                    </b-col>
                    <b-col cols="3" class="text-center font-weight-bold" v-else>-</b-col>
                </b-row>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import pricesJson from '../../../files/prices.json'
    import optionsJson from '../../../files/options.json'
    import priceOptions from '../../../files/price-options.json'
    import domainsJson from "../../../files/domains.json";

    export default {
        name: "PriceOption",
        data() {
            return {
                prices: pricesJson,
                options: optionsJson,
                priceOptions: priceOptions,
                domains: domainsJson,
            }
        },
        props: ["price", "domainSelected"],
    }
</script>

<style scoped>

</style>
