var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row contentTop" }, [
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [_c("logo")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-8 connecte" },
          [_c("login")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }