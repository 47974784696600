var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentStep
    ? _c(
        "div",
        { staticClass: "blocGreen" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "justify-content-md-center" },
                _vm._l(_vm.steps, function(step, index) {
                  return _c(
                    "b-col",
                    {
                      key: index,
                      staticClass: "text-center navig",
                      class:
                        _vm.currentStep && _vm.currentStep === index + 1
                          ? "actif"
                          : "",
                      attrs: { cols: "4", sm: "4", md: "2" }
                    },
                    [
                      index < _vm.currentStep
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: step.url,
                                  params: {
                                    lng: _vm.lng,
                                    slug: step.slug,
                                    id: step.id
                                  }
                                },
                                "active-class": "active"
                              }
                            },
                            [
                              _c("step-domain", {
                                attrs: { index: index + 1, step: step.name }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("step-domain", {
                                attrs: { index: index + 1, step: step.name }
                              })
                            ],
                            1
                          )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }