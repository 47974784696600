var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentUser
    ? _c("validation-observer", {
        ref: "observer",
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var handleSubmit = ref.handleSubmit
                return [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return handleSubmit(_vm.onSubmit)
                        }
                      }
                    },
                    [
                      _vm.example && _vm.account && _vm.countries
                        ? _c(
                            "b-container",
                            { staticClass: "page " },
                            [
                              _vm.example.topic.domain
                                ? _c("b-row", { staticClass: "titre mb-2" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "domaines." +
                                              _vm.example.topic.domain.title
                                          )
                                        )
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "stitre font-weight-bold" },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "domaines." + _vm.example.topic.title
                                        )
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-row", { staticClass: "mb-2" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("examples." + _vm.example.title)
                                    )
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "mb-2 calcul" },
                                [
                                  _c("example-table", {
                                    attrs: {
                                      example: _vm.example,
                                      country: _vm.account.countryProfil
                                        ? _vm.account.countryProfil
                                        : _vm.defaultCountry,
                                      account: _vm.account
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.account.lastOrder &&
                              _vm.account.lastOrder.price &&
                              _vm.account.lastOrder.price.name !== "free" &&
                              _vm.account.lastOrder.price.name != "solo"
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "8", md: "6" } },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-select",
                                                {
                                                  attrs: { size: "sm" },
                                                  model: {
                                                    value: _vm.countryCompare,
                                                    callback: function($$v) {
                                                      _vm.countryCompare = $$v
                                                    },
                                                    expression: "countryCompare"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-form-select-option",
                                                    { attrs: { value: null } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "website.comparer"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.countries,
                                                    function(country) {
                                                      return !_vm.account
                                                        .countryProfil ||
                                                        country.code !==
                                                          _vm.account
                                                            .countryProfil.code
                                                        ? _c(
                                                            "b-form-select-option",
                                                            {
                                                              key: country.id,
                                                              attrs: {
                                                                value: country
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "countries." +
                                                                        country.code
                                                                    )
                                                                  ) +
                                                                  "\n                            "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.countryChanged
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-2 calcul" },
                                    [
                                      _c("example-table", {
                                        attrs: {
                                          example: _vm.example,
                                          country: _vm.countryCompare,
                                          account: _vm.account
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.account
                                ? _c(
                                    "b-row",
                                    { staticClass: "mt-4 mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c("b-row", {
                                            staticClass:
                                              "stitre font-weight-bold",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "website.calculerhonorairescreation"
                                                )
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-row",
                                            { staticClass: "left" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mt-1" },
                                                [_vm._v("|")]
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "ml-1 mr-1 mt-1",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t("website.enquete")
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("b-checkbox", {
                                                on: {
                                                  change: _vm.toggleFigure
                                                },
                                                model: {
                                                  value: _vm.figure,
                                                  callback: function($$v) {
                                                    _vm.figure = $$v
                                                  },
                                                  expression: "figure"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "mt-1" },
                                                [_vm._v("|")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "ml-1 mr-1 mt-1",
                                                  attrs: {
                                                    to: {
                                                      name: "profil",
                                                      params: { lng: _vm.lng }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass: "mt-1",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.$t(
                                                          "website.reglages"
                                                        )
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { sm: "12" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("example-calcul", {
                                            attrs: {
                                              example: _vm.example,
                                              account: _vm.account
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("bottom-actions", {
                        attrs: {
                          "button-text": _vm.$t(
                            "website.03_bt_calculer_droits"
                          ),
                          "button-text2": _vm.$t("website.03_bt_devis_direct"),
                          "set-action": _vm.setAction
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          1435387818
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }