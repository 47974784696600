<template>
    <b-container>
        <b-row>
            <b-col cols="12" class="page" v-if="domain">
                <b-row class="mb-4">
                    <img src="">
                    <img :src="'/images/graph-' + (domain.id === 11 ? 'iteration' : 'disciplines') + '_' + lng + '.svg'" alt=""/>
                </b-row>
                <b-row class="titre mb-4">
                    <div v-html="$t('domaines.' + domain.title)"/>
                </b-row>
                <b-row v-for="topic in domain.topics" :key="topic.id" class="topic">
                    <b-col sm="12" md="3" v-html="$t('domaines.' + topic.title)" class="titre"/>
                    <b-col>
                        <b-row>
                            <b-col sm="12" md="4" v-for="example in topic.examples" :key="example.id" class="mb-3">
                                <router-link
                                    v-if="checkAccessLink(example)"
                                    :to="{ name: 'calcul', params: {slug: example.title, id: example.id, lng: lng}}"
                                    v-html="'<span'+(example.isFree ? ' class=free' : '')+'>&bull; <strong>' + (example.isFree ? 'FREE ' : '') + '</strong></span>' + $t('examples.' + example.title)"
                                />
                                <div
                                    v-else
                                    v-html="'<span'+(example.isFree ? ' class=free' : '')+'>&bull; <strong>' + (example.isFree ? 'FREE ' : '') + '</strong></span>' + $t('examples.' + example.title)"
                                    v-b-tooltip.hover.bottom="{ customClass: 'my-tooltip' }" :title="$t('website.abominrequis') + (example.isFree ? 'FREE ' : (account && account.lastOrder && account.lastOrder.price && account.lastOrder.price.name === 'solo') ? 'PRO' : 'SOLO')"
                                    class="text-primary"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" class="text-center" v-else>
                <Loading/>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import axios from "axios";
    import {serverBus} from "../../../../app";
    import Loading from "../../../components/front/Loading";

    export default {
        name: "Domain",
        components: {Loading},
        data() {
            return {
                account: null,
                domain: null,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            currentUser() {
                return this.$store.state.currentUser
            },
        },
        watch: {
            $route(to, from) {
                this.getDomain()
            },
            currentUser(newUser, oldUser) {
                this.getAccount()
            },
        },
        mounted() {
            this.getDomain()
            if(this.$store.state.currentUser && !this.$store.state.accessToken) {
                this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                return false
            }
            if(this.$store.state.currentUser) {
                this.getAccount()
            }
        },
        methods: {
            getDomain() {
                this.$store.commit("setDomainStep", 2)
                this.$store.commit("setDomains", true)

                const url = this.$store.state.apiUrl + '/api/front/domains/' + this.$route.params.id
                serverBus.$emit('domain', this.$route.params)
                axios.get(
                    url
                ).then(
                    response => {
                        this.domain = response.data
                        this.$store.commit("setElement", {'key': 'domain', 'value': this.domain})
                    }
                )
            },
            getAccount() {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                    }
                )
            },
            checkAccessLink(example) {
                //console.log(this.getActiveOrders())
                return this.account &&
                    (
                        example.isFree ||
                        this.checkActiveOrders(example) ||
                        this.account.enterprise && this.account.enterprise.lastOrder
                    )
            },
            checkActiveOrders(example) {
                let check = false
                this.account.orders.forEach((order) => {
                    if(order.active) {
                        const tcheck = (!order.price || order.price.name !== 'free') &&
                        (
                            (order.price && order.price.name !== 'solo') ||
                            (order.domain && order.domain.title === this.domain.title) ||
                            (order.example && order.example === example['@id'])
                        )
//                        console.log(tcheck)
                        if (tcheck) {
                            check = tcheck
                        }
                    }
                })
                return check
            },
            getActiveOrders() {
                let activeOrders = []
                this.account.orders.forEach((order) => {
                    if(order.active) {
                        activeOrders.push(order)
                    }
                })
                return activeOrders
            }

        }
    }
</script>

<style scoped>
</style>
