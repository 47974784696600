<template>
    <div>
        <div class="titre mb-2" v-html="$t('website.mentions')"></div>
        <div v-html="$t('website.notice')"></div>
    </div>
</template>

<script>
    export default {
        name: "Mentions"
    }
</script>

<style scoped>

</style>
