<template>
    <tab-input
        url="content_types"
        title="Translations"
        add-text="add a section"
        :make-toast="makeToast"
        title-notification="Section Notification"
        name2="ContentKeys"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "ContentTypes-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>

</style>
