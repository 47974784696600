var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "lg", type: "dark", variant: "info" } },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c("b-navbar-brand", { attrs: { href: "#" } }, [
            _c("div", { staticClass: "logo" }, [
              _vm._v("CalKulator "),
              _c("span", { staticClass: "version" }, [_vm._v("4.3")])
            ])
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _vm.pseudo
            ? _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    [
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { text: "Users" } },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-clients" } } },
                                [_vm._v("Clients")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-partners" } } },
                                [_vm._v("Partners")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: { name: "admin-discount-codes" }
                                  }
                                },
                                [_vm._v("Discount Codes")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { text: "Domains" } },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-domains" } } },
                                [_vm._v("Domains")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-topics" } } },
                                [_vm._v("Topics")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-examples" } } },
                                [_vm._v("Examples")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { text: "Config" } },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: { name: "admin-price-options" } }
                                },
                                [_vm._v("Price Options")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-prices" } } },
                                [_vm._v("Prices")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-options" } } },
                                [_vm._v("Options")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-profils" } } },
                                [_vm._v("Profils types")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-activities" } } },
                                [_vm._v("Activities types")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-currencies" } } },
                                [_vm._v("Currencies")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-countries" } } },
                                [_vm._v("Countries")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: { name: "admin-countries-groups" }
                                  }
                                },
                                [_vm._v("Countries groups")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: { name: "admin-copyright-factors" }
                                  }
                                },
                                [_vm._v("Copyright factors")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: { name: "admin-copyright-types" }
                                  }
                                },
                                [_vm._v("Copyright types")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-criteria" } } },
                                [_vm._v("Criteria")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { text: "Content" } },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: { name: "admin-translations" } }
                                },
                                [_vm._v("Translations")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "admin-languages" } } },
                                [_vm._v("Languages")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c(
                        "b-nav-item",
                        { attrs: { right: "" } },
                        [
                          _c("b-avatar", {
                            attrs: {
                              button: "",
                              variant: "light",
                              text: _vm.pseudo.preferred_username
                            },
                            on: { click: _vm.logout }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }