var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blocGreenBottom" },
    [
      _c(
        "b-container",
        { class: _vm.alignButton ? _vm.alignButton : "text-center" },
        [
          _vm.buttonText
            ? _c("b-button", {
                staticClass: "mb-1 mt-1",
                attrs: { variant: "primary", type: "submit" },
                domProps: { innerHTML: _vm._s(_vm.buttonText) },
                on: {
                  click: function($event) {
                    return _vm.setAction("buttonText")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.buttonText2
            ? _c("b-button", {
                staticClass: "mb-1 mt-md-1",
                attrs: { variant: "primary", type: "submit" },
                domProps: { innerHTML: _vm._s(_vm.buttonText2) },
                on: {
                  click: function($event) {
                    return _vm.setAction("buttonText2")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }