var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "calculLine mb-3" },
    [
      _c(
        "b-col",
        [
          _vm.type
            ? _c(_vm.type, {
                tag: "component",
                attrs: {
                  name: _vm.name,
                  currency: _vm.currency,
                  "model-h": _vm.modelH,
                  index: _vm.index,
                  total: _vm.total
                }
              })
            : _c("div", [
                _vm._v("\n            " + _vm._s(_vm.name) + "\n        ")
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }