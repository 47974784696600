<template>
    <tab-input
        url="copyright_factors"
        title="Copyright factors"
        add-text="add a copyright factor"
        :make-toast="makeToast"
        title-notification="Factor Notification"
        name= "CopyrightFactor"
        generate-file="copyrightfactors"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "CopyrightFactors-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
