<template>
    <b-container>
        <component v-if="name" v-bind:is="name" name="name" class="page"/>
    </b-container>
</template>

<script>
    import Aide from "./page/Aide";
    import Mentions from "./page/Mentions";
    import Preambule from "./page/Preambule";

    export default {
        name: "Page",
        components: {Aide, Mentions, Preambule},
        computed: {
            name() {
                return this.$route.name
            }
        },
    }
</script>

<style scoped>
</style>
