var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "col-sm-12 col-md-8" },
    [
      _c(
        "b-col",
        { attrs: { cols: "9" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols": "2",
                "label-size": "sm",
                label: _vm.language.code,
                "label-for":
                  "input-sm" + _vm.language.id + "-" + _vm.contentKey.id
              }
            },
            [
              _vm.content
                ? _c("b-form-textarea", {
                    attrs: {
                      id:
                        "input-sm" + _vm.language.id + "-" + _vm.contentKey.id,
                      size: "sm"
                    },
                    model: {
                      value: _vm.content.text,
                      callback: function($$v) {
                        _vm.$set(_vm.content, "text", $$v)
                      },
                      expression: "content.text"
                    }
                  })
                : _c("b-form-textarea", {
                    attrs: {
                      id:
                        "input-sm" + _vm.language.id + "-" + _vm.contentKey.id,
                      size: "sm"
                    },
                    model: {
                      value: _vm.newContent.text,
                      callback: function($$v) {
                        _vm.$set(_vm.newContent, "text", $$v)
                      },
                      expression: "newContent.text"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { staticClass: "text-right mt-1" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "primary" },
              on: {
                click: function($event) {
                  _vm.content
                    ? _vm.save(_vm.content, _vm.language)
                    : _vm.create(_vm.language)
                }
              }
            },
            [_vm._v("save")]
          ),
          _vm._v(" "),
          _vm.content
            ? _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.del(_vm.content)
                    }
                  }
                },
                [_vm._v("delete")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }