<template>
    <div>
        <div class="titre mb-2" v-html="$t('website.preambule')"></div>
        <div v-html="$t('website.00c_preambule')"></div>
    </div>
</template>

<script>
    export default {
        name: "Preambule"
    }
</script>

<style scoped>

</style>
