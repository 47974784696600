<template>
    <b-container v-if="partners && partners.length > 0" class="text-center mt-5 mb-5">

        <a v-for="partner in partners" :href="partner.url" target="_blank">
            <img :src="$store.state.apiUrl + '/images/partners/' + partner.image.filePath" alt=""/>
        </a>
    </b-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "BottomPartners",
        data() {
            return {
                partners: null
            }
        },
        mounted() {
            this.getPartners()
        },
        methods: {
            getPartners() {
                axios.get(
                    this.$store.state.apiUrl + '/api/front/partners?menu=1'
                ).then(
                    response => {
                        this.partners = response.data["hydra:member"]
                    }
                )
            }
        },
    }
</script>

<style scoped>

</style>
