<template>
    <b-container class="page" v-if="account">
        <b-row class="titre mb-4">
            <div v-html="$t('website.00j_compare_titre')"/>
        </b-row>
        <b-row>
            <discipline-table
                :country="account.country"
                :currency="account.currency"
            />
            <b-col sm="12">
                <b-row>
                    <b-col sm="8" md="6" class="mt-2 mb-2" v-if="countries">
                        <b-row>
                            <b-select
                                size="sm"
                                v-model="countryCompare"
                            >
                                <b-form-select-option :value="null">{{ $t('website.comparer') }}</b-form-select-option>
                                <b-form-select-option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :value="country"
                                    v-if="!account.countryProfil || country.code !== account.countryProfil.code"
                                >
                                    {{ $t('countries.' + country.code) }}
                                </b-form-select-option>
                            </b-select>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <discipline-table
                :country="countryCompare"
                :currency="account.currency"
                v-if="countryChanged"
            />
        </b-row>
    </b-container>
</template>

<script>
    import DisciplineTable from "../../../components/front/DisciplineTable";
    import axios from "axios";

    export default {
        name: "Disciplines",
        components: {DisciplineTable},
        data() {
            return {
                countryCompare: null,
                account: null,
                countries: null,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            countryChanged() {
                return this.countryCompare
            },
        },
        mounted() {
            this.getAccount()
        },
        methods: {
            getAccount() {
                this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                    data => {
                        this.account = data
                        this.getCountries()
                    }
                )
            },
            getCountries() {
                const url = this.$store.state.apiUrl + '/api/countries?exists[group]=true&pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.countries = response.data["hydra:member"]
                    }
                )
            },
        }
    }
</script>

<style scoped>
</style>
