<template>
    <div>
        <b-card-text>
            <template>
                <b-row>
                    <b-col cols="10">
                        <div>{{ client.email }}</div>
                        <div>{{ client.password }}&nbsp;</div>
                    </b-col>
                    <b-col>
                        <div v-if="client.keycloakId" class="text-right"><b-icon-lock-fill variant="primary"/></div>
                    </b-col>
                </b-row>
            </template>
            <div class="priceName">
                <span v-if="client.lastOrder.price">{{ client.lastOrder.price.name }}</span>
                <span v-else-if="client.lastOrder.example">
                    {{ client.lastOrder.example }}
<!--                    <span v-html="$t('domaines.' + client.lastOrder.example.topic.domain.title)"/> /
                    <span v-html="$t('domaines.' + client.lastOrder.example.topic.title)"/> /
                    <span v-html="$t('examples.' + client.lastOrder.example.title)"/>-->
                </span>
                <span v-else><pre>{{ client }}</pre></span>
            </div>
            <div>
<!--                {{ client }}-->
                <b-row>
                    <b-col cols="4">{{ client.lastOrder.paymentDate | moment("DD/MM/YYYY") }}</b-col>
                    <b-col cols="2">=></b-col>
                    <b-col cols="4">
                        <h6>
                            <b-badge
                                size="sm"
                                :variant="client.lastOrder.active ? 'success': 'danger'"
                            >
                                {{ client.lastOrder.validationDate | moment("DD/MM/YYYY") }}
                            </b-badge>
                        </h6>
                    </b-col>
                </b-row>
            </div>
            <div class="mt-2">
                <span v-for="(order,index) in client.orders" :key="order.id">
                    <b-badge size="sm" :variant="(index === 0) ? 'primary' : ''">N°{{ order.id }}</b-badge>
                    <span v-if="index < client.orders.length -1 ">- </span>
                </span>
            </div>
        </b-card-text>

        <b-button href="#" variant="primary" size="sm" @click="detail(client)">details</b-button>
    </div>
</template>

<script>
    import axios from "axios";
    import querystring from "querystring";

    export default {
        name: "Client",
        props: ["client", "checkKeycloak", "getRole"],
        methods: {
            detail(client) {
                const url = this.$store.state.apiUrl + client["@id"]
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.$parent.clientActif = response.data
                        if (!this.$parent.clientActif.keycloakId) {
                            this.checkKeycloak(this.$parent.clientActif)
                        }
                        this.getRole(this.$parent.clientActif)
                        this.$bvModal.show('modalClient')
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
