<template>
    <div>
        <header-admin></header-admin>
        <component
            v-if="page"
            v-bind:is="page"
            name="page"
            :make-toast="makeToast"
        />
        <login
            v-if="!pseudo"
        />
    </div>
</template>

<script>
    import Activities from "./pages/admin/config/Activities";
    import ContentTypes from "./pages/admin/translations/ContentTypes";
    import CopyrightFactors from "./pages/admin/config/CopyrightFactors";
    import CopyrightTypes from "./pages/admin/config/CopyrightTypes";
    import Countries from "./pages/admin/config/Countries";
    import CountriesGroups from "./pages/admin/config/CountriesGroups";
    import Criteria from "./pages/admin/config/Criteria";
    import Currencies from "./pages/admin/config/Currencies";
    import DiscountCodes from "./pages/admin/users/DiscountCodes";
    import HeaderAdmin from "./components/header/MenuAdmin";
    import Language from "./pages/admin/translations/Language";
    import List from "./pages/admin/List";
    import Login from "./pages/admin/Login";
    import Options from "./pages/admin/config/Options";
    import Partners from "./pages/admin/users/Partners";
    import Prices from "./pages/admin/config/Prices";
    import PriceOptions from "./pages/admin/config/PriceOptions";
    import Profils from "./pages/admin/config/Profils";

    export default {
        name: "AppAdmin",
        components: {
            Activities, ContentTypes, CopyrightFactors, CopyrightTypes, Countries, CountriesGroups, Criteria, Currencies,
            DiscountCodes, HeaderAdmin, Language, List, Login, Options, Partners, Prices, PriceOptions, Profils
        },
        mounted() {
            this.$store.commit('setAdmin', true)

            if(this.$route.meta.page && !this.$store.state.accessToken) {
                this.$router.push('/admin')
            }
        },
        computed: {
            page() {
                if(this.$store.state.accessToken) {
                    return this.$route.meta.page
                }
            },
            pseudo() {
                return this.$store.state.currentUser
            }
        },
        methods: {
            makeToast(msg, variant, title) {
                this.$bvToast.toast(msg, {
                    title: title,
                    variant: variant,
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                })
            },
        }
    }
</script>

<style scoped>

</style>
