var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "calculLine mb-3 text-justify" },
    [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("website.04_p01_supports")) }
      }),
      _vm._v(" "),
      _c("b-textarea", { staticClass: "mt-2 mb-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }