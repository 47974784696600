var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "h5",
        [
          _c("b-badge", { attrs: { variant: "primary" } }, [
            _vm._v("Price options")
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.priceOptions
        ? _c(
            "b-button",
            { attrs: { variant: "primary", disabled: "" } },
            [
              _c("b-spinner", { attrs: { small: "", type: "grow" } }),
              _vm._v("\n        Loading...\n    ")
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c("b-col"),
                  _vm._v(" "),
                  _vm._l(_vm.prices, function(price) {
                    return _c("b-col", { key: price.id, staticClass: "name" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(price.name) +
                          "\n            "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.options, function(option) {
                return _c(
                  "b-row",
                  { key: option.id, staticClass: "mb-1" },
                  [
                    _c("b-col", { staticClass: "mr-1", class: option.color }, [
                      _c("i", { class: "icon-ICO" + option.icon }),
                      _c("span", {
                        staticClass: "ml-2",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("tarifs." + option.name))
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.prices, function(price) {
                      return _c(
                        "b-col",
                        {
                          key: price.id,
                          staticClass: "text-center mr-1",
                          class: option.color
                        },
                        [
                          option.type !== "state"
                            ? _c("b-form-input", {
                                attrs: {
                                  value: _vm.priceOptions[
                                    price.id + "_" + option.id
                                  ]
                                    ? _vm.priceOptions[
                                        price.id + "_" + option.id
                                      ]["value"]
                                    : "",
                                  size: "sm",
                                  type:
                                    option.type === "amount" ||
                                    option.type === "number"
                                      ? "number"
                                      : "text"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.saveOption(
                                      $event,
                                      price.id,
                                      option.id,
                                      option.type
                                    )
                                  }
                                }
                              })
                            : _c("b-form-checkbox", {
                                attrs: {
                                  switch: "",
                                  size: "sm",
                                  checked:
                                    _vm.priceOptions[
                                      price.id + "_" + option.id
                                    ] &&
                                    _vm.priceOptions[
                                      price.id + "_" + option.id
                                    ]["value"] === true
                                      ? "true"
                                      : "false"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.saveOption(
                                      $event,
                                      price.id,
                                      option.id,
                                      option.type
                                    )
                                  }
                                }
                              })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }