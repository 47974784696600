var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "router-link",
        { attrs: { to: { name: "home", params: { lng: _vm.lng } } } },
        [
          _c("div", { staticClass: "logo" }, [
            _vm._v(_vm._s(_vm.$t("website.logo")) + " "),
            _c("span", { staticClass: "version" }, [
              _vm._v(_vm._s(_vm.$t("website.version")))
            ])
          ]),
          _vm._v(" "),
          !_vm.admin
            ? _c("div", { staticClass: "baseline" }, [
                _vm._v(_vm._s(_vm.$t("website.baseline")))
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }