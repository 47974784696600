<template>
    <b-container>
        <b-row class="mb-1" v-for="(field, index) in fields" :key="index" v-if="field.show">
            <b-col sm="3">
                <label v-html="$t('website.page_souscrire_'+field.name)"/>
            </b-col>
            <b-col sm="9">
                <validation-provider
                    :name="field.key"
                    rules="required"
                    v-slot="validationContext"
                    v-if="(field.name ==='pays')"
                >
                    <b-form-select
                        v-model="form[field.key]['@id']"
                        :state="getValidationState(validationContext)"
                        :aria-describedby="'input-live-feedback'"
                        size="sm"
                        class="mb-3"
                        @change="changeSelect(field.key)"
                    >
                        <b-form-select-option value="null" v-html="$t('website.choisirpays')"/>
                        <b-form-select-option
                            :value="country['@id']"
                            v-for="country in countries"
                            :key="country.id"
                            v-if="countries"
                        >
                            {{ $t('countries.' + country.code) }}
                        </b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback :id="'input-live-feedback'">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>

                <validation-provider
                    :name="field.key"
                    :rules="field.rule"
                    v-slot="validationContext"
                    v-else
                >
                    <b-form-group :id="'example-input-group-'+index">
                        <b-input-group :prepend="prepend && field.prepend ? prepend : ''" size="sm">
                            <b-form-input
                                :id="'input-small-'+index"
                                :name="'input-small-'+index"
                                v-model="form[field.key]"
                                :state="getValidationState(validationContext)"
                                :aria-describedby="'input-'+index+'-live-feedback'"
                                size="sm"
                                :type="(field.key ==='password' || field.key ==='confirmation') ? 'password' : 'text'"
                                @blur="blurInput(field.key)"
                            ></b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback :id="'input-'+index+'-live-feedback'">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-row>
        <b-row align-h="end" class="mt-2">
            <b-col sm="9" v-html="$t('website.page_souscrire_obligatoire')"></b-col>
        </b-row>
    </b-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Subscription",
        props: ["account", "form"],
        data() {
            return {
                fields: [
                    {name: 'nom', key: 'lastname', show: true, rule: 'required: true'},
                    {name: 'prenom', key: 'firstname', show: true, rule: 'required: true'},
                    {name: 'profession', key: 'job', show: true, rule: null},
                    {name: 'societe', key: 'society', show: true, rule: null},
                    {name: 'adresse', key: 'address', show: true, rule: 'required: true'},
                    {name: 'zip', key: 'zipcode', show: true, rule: 'required: true'},
                    {name: 'ville', key: 'city', show: true, rule: 'required: true'},
                    {name: 'pays', key: 'country', show: true, rule: 'required: true'},
                    {name: 'numerotva', key: 'vatNumber', show: false, rule: null, prepend: true},
                    {name: 'tel', key: 'phone', show: true, rule: 'required: true'},
                    {name: 'email', key: 'email', show: true, rule: 'required|email'},
                ],
                fieldsPass: [
                    {name: 'mdp', key: 'password', show: true, rule: 'required|confirmed:password'},
                    {name: 'confirmation', key: 'confirmation', show: true, rule: 'required|confirmed:password'},
                ],
                countries: null,
                prepend: null,
            }
        },
        created() {
            this.getCountries()
            if(this.account) {
                Object.keys(this.form).forEach((key) => {
                    this.form[key] = this.account[key]
                })
            } else {
                this.form.country = {}
                this.form.country['@id'] = null
                this.fields = this.fields.concat(this.fieldsPass)
            }
        },

        methods: {
            getCountries() {
                const url = this.$store.state.apiUrl + '/api/front/countries?pagination=false'
                axios.get(
                    url
                ).then(
                    response => {
                        this.countries = response.data["hydra:member"]
                        this.$store.commit("setAction", 'validSubscription')
                    }
                )
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            changeSelect(key) {
                this.prepend = null
                this.fields[8].show = false
                this.fields[8].rule = null
                this.countries.forEach((country) => {
                    if(country['@id'] === this.form[key]['@id']) {
                        if(country.ue && country.code.toLowerCase() !== 'fr') {
                            this.fields[8].show = true
                            //this.fields[8].rule = 'required:true'
                            this.prepend = country.vatCode ? country.vatCode : country.code
                        }
                    }
                })
            },
            blurInput(key) {
                if(key === 'vatNumber') {
                    this.checkVatNumber(key)
                }
            },
            checkVatNumber(key) {
                const url = '/vat/check/' + this.prepend.toUpperCase() + '/' + this.form[key]
                axios.get(
                    url
                ).then(
                    response => {
                        if(response.valid) {
                            //console.log(response)
                        } else {
                            this.form[key] = ''
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>
