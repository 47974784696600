<template>
    <tab-input
        url="profils"
        title="Profils types"
        add-text="add a profil type"
        :make-toast="makeToast"
        title-notification="Profil Notification"
        generate-file="profilstypes"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Profils-Admin",
        props: ["makeToast"],
        components: {TabInput},
        data() {
            return {
                toastVariant: null,
            }
        },
    }
</script>

<style scoped>
</style>
