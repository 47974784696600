<template>
    <b-container fluid>
        <h5><b-badge variant="primary">{{ title }}</b-badge></h5>
        <b-button variant="primary" disabled v-if="!elements">
            <b-spinner small type="grow"></b-spinner>
            Loading...
        </b-button>
        <b-row v-if="elements">
            <b-col>
                <b-tabs content-class="mt-3">
                    <b-tab
                        lazy
                        v-for="element in elements"
                        :key="element.id"
                        :title="element.email ? element.email : (element.code ? element.code : (element.title ? element.title : element.name))"
                        :title-link-class="element.state ? 'success' : 'danger'"
                    >
                        <component
                            :is="name"
                            :element="element"
                            :url="url"
                            :get-elements="getElements"
                            :make-toast="makeToast"
                            :title-notification="titleNotification"
                            v-if="name"
                        />
                        <name-state
                            :element="element"
                            :url="url"
                            :get-elements="getElements"
                            :make-toast="makeToast"
                            :title-notification="titleNotification"
                            v-else
                        />
                        <component
                            :is="name2"
                            :element="element"
                            :url="url"
                            :get-elements="getElements"
                            :make-toast="makeToast"
                            :title-notification="titleNotification"
                            v-if="name2"
                        />
                    </b-tab>
                    <b-tab :title="addText">
                        <component
                            :is="name"
                            :priority="elements ? elements.length : 1"
                            :url="url"
                            :title-notification="titleNotification"
                            :get-elements="getElements"
                            :make-toast="makeToast"
                            v-if="name"
                        />
                        <name-state
                            :priority="elements ? elements.length : 1"
                            :url="url"
                            :title-notification="titleNotification"
                            :get-elements="getElements"
                            :make-toast="makeToast"
                            v-else
                        />
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import axios from "axios";
    import ContentType from "./ContentType";
    import ContentKeys from "../../pages/admin/translations/ContentKeys";
    import Country from "./Country";
    import CountryGroup from "./CountryGroup";
    import CopyrightFactor from "./CopyrightFactor";
    import CopyrightType from "./CopyrightType";
    import Criterion from "./Criterion";
    import Currency from "./Currency";
    import DiscountCode from "./DiscountCode";
    import Language from "./Language";
    import NameState from "./NameState";
    import Partner from "./Partner";
    import Price from "./Price";
    import Option from "./Option";
    import querystring from "querystring";

    export default {
        name: "TabInput.vue",
        data() {
            return {
                elements: null,
            }
        },
        props: ["name", "name2", "title", "url", "addText", "makeToast", "titleNotification", "generateFile"],
        components: {ContentKeys, ContentType, Country, CountryGroup, CopyrightFactor, CopyrightType, Criterion,
            Currency, DiscountCode, Language, NameState, Partner, Price, Option},
        mounted() {
            this.getElements()
        },
        methods: {
            getElements() {
                const url = this.$store.state.apiUrl + '/api/' + this.url + '?pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.elements = response.data["hydra:member"]
                        if(this.generateFile) {
                            this.generate()
                        }
                    }
                )
            },

            generate() {
                const url = '/generate/file/' + this.generateFile
                axios.post(
                    url,
                    querystring.stringify(
                        {
                            elements: JSON.stringify(this.elements)
                        }
                    )
                ).then(
                    response => {
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
