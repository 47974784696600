<template>
    <b-container class="mt-5">
        <b-row class="justify-content-md-center mb-2">
            <b-col cols="10" md="6">
                <b-form-input
                    v-model="username"
                    type="email"
                    placeholder="email"
                />
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center mb-2">
            <b-col cols="10" md="6">
                <b-form-input
                    v-model="password"
                    type="password"
                    placeholder="password"
                    @keyup.enter="signin"
                />
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center mb-2">
            <b-col cols="10" md="6">
                <b-button variant="primary" @click="signin">sign in</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {serverBus} from "../../../app";

    export default {
        name: "Login",
        //props: ["accessLogin"],
        data() {
            return {
                username: null,
                password: null,
            }
        },
        methods: {
            signin() {
                this.$accessLogin(this.username, this.password)
                    .then(token => {this.$setTokens(token)})
                    .then(token => {
                        this.$getUserinfo().then(
                            userinfo => {
                                this.$store.commit('setUser', userinfo)
                                this.$checkUser(userinfo).then(
                                    user => {
                                        this.$checkSession(user).then(
                                            session => {
                                                if(session.length > 1) {
                                                    this.logout()
                                                }
                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }
                )
            },
            logout() {
                serverBus.$emit('logout', true)
            },
        }
    }
</script>

<style scoped>

</style>
