<template>
    <tab-input
        url="copyright_types"
        title="Copyright types"
        add-text="add a copyright type"
        :make-toast="makeToast"
        title-notification="Type Notification"
        name= "CopyrightType"
        generate-file="copyrighttypes"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "CopyrightTypes-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
