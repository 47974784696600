import VueRouter from "vue-router";

export default new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history',
    routes: [

        {path: '/admin/', name: 'admin'},
        {path: '/admin/price-options', name: 'admin-price-options', meta: {page: 'PriceOptions', name: 'price-options'}},
        {path: '/admin/prices', name: 'admin-prices', meta: {page: 'Prices', name: 'prices'}},
        {path: '/admin/options', name: 'admin-options', meta: {page: 'Options', name: 'options'}},
        {path: '/admin/profils', name: 'admin-profils', meta: {page: 'Profils', name: 'profils'}},
        {path: '/admin/activities', name: 'admin-activities', meta: {page: 'Activities', name: 'activities'}},
        {path: '/admin/countries', name: 'admin-countries', meta: {page: 'Countries', name: 'countries'}},
        {path: '/admin/currencies', name: 'admin-currencies', meta: {page: 'Currencies', name: 'currencies'}},
        {path: '/admin/countries-groups', name: 'admin-countries-groups', meta: {page: 'CountriesGroups', name: 'countries-groups'}},
        {path: '/admin/copyright-factors', name: 'admin-copyright-factors', meta: {page: 'CopyrightFactors', name: 'copyright-factors'}},
        {path: '/admin/copyright-types', name: 'admin-copyright-types', meta: {page: 'CopyrightTypes', name: 'copyright-types'}},
        {path: '/admin/criteria', name: 'admin-criteria', meta: {page: 'Criteria', name: 'criteria'}},

        {path: '/admin/clients', name: 'admin-clients', meta: {page: 'List', name: 'clients'}},
        {path: '/admin/partners', name: 'admin-partners', meta: {page: 'Partners', name: 'partners'}},
        {path: '/admin/discount-codes', name: 'admin-discount-codes', meta: {page: 'DiscountCodes', name: 'discount-codes'}},
        {path: '/admin/discount-checks', name: 'admin-discount-checks', meta: {page: 'DiscountChecks', name: 'discount-checks'}},
        {path: '/admin/domains', name: 'admin-domains', meta: {page: 'List', name: 'domains'}},
        {path: '/admin/topics', name: 'admin-topics', meta: {page: 'List', name: 'topics'}},
        {path: '/admin/examples', name: 'admin-examples', meta: {page: 'List', name: 'examples'}},
        {path: '/admin/translations', name: 'admin-translations', meta: {page: 'ContentTypes', name: 'translations'}},
        {path: '/admin/languages', name: 'admin-languages', meta: {page: 'Language', name: 'languages'}},

        {path: '/:lng/', name: 'home', meta: {page: 'Home'}},
        {path: '/:lng/disciplines', name: 'disciplines', meta: {page: 'Disciplines', name: 'disciplines'}},
        {path: '/:lng/discipline', name: 'discipline', meta: {page: 'Discipline', name: 'discipline'}},
        {path: '/:lng/domaine/:slug-:id', name: 'domaine', meta: {page: 'Domain', name: 'domaine'}},

        {path: '/:lng/register', name: 'register', meta: {page: 'Register'}},
        {path: '/:lng/upgrade', name: 'upgrade', meta: {page: 'Register'}},
        {path: '/:lng/paiement', name: 'paiement', meta: {page: 'Payment'}},
        {path: '/:lng/confirmation', name: 'confirmation', meta: {page: 'Confirmation'}},
        {path: '/:lng/account', name: 'account', meta: {page: 'Account'}},
        {path: '/:lng/entreprise', name: 'enterprise', meta: {page: 'Enterprise'}},
        {path: '/:lng/profil', name: 'profil', meta: {page: 'Profil'}},
        {path: '/:lng/achat_exemple', name: 'example', meta: {page: 'Example'}},
        {path: '/:lng/paiement_exemple', name: 'example-paiement', meta: {page: 'ExamplePayment'}},
        {path: '/:lng/lost', name: 'lostpwd', meta: {page: 'Lost'}},
        {path: '/:lng/recovery/:token', name: 'recovery', meta: {page: 'Recovery'}},

        {path: '/:lng/mentions_et_conditions', name: 'mentions', meta: {page: 'Page'}},
        {path: '/:lng/preambule', name: 'preambule', meta: {page: 'Page'}},
        {path: '/:lng/aide', name: 'aide', meta: {page: 'Page'}},

        {path: '/:lng/calcul/:slug-:id', name: 'calcul', meta: {page: 'Calcul', name: 'calcul'}},
        {path: '/:lng/droits', name: 'droits', meta: {page: 'Droits', name: 'droits'}},
        {path: '/:lng/devis', name: 'devis', meta: {page: 'Devis', name: 'devis'}},
    ]
});
