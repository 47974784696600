import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        admin: false,
        domains: false,
        apiUrl: null,
        keycloakApiUrl: null,
        oauthUrl: null,
        realm: null,
        /*clientId: null,
        clientSecret: null,*/
        currentUser: null,
        currentPrice: null,
        actLng: 'fr',
        tva: 1.2,
        action: null,
        subscriptionDuration: 15,
        patchHeader: 'application/merge-patch+json',
        postHeader: 'application/ld+json',
        refreshToken: null,
        accessToken: null,
        tokensExpiry: null,
        stripeKey: null,
        domainStep: null,
        orderBill: null,
        price: null,
        options: null,
        priceOptions: null,
        domainPrice: null,
    },
    mutations: {
        setAdmin(state, value) {
            state.admin = value
        },
        setDomains(state, value) {
            state.domains = value
        },
        setAction(state, value) {
            state.action = value
        },
        setAdminClient(state, value) {
            state.adminClient = value
        },
        setCurrentPrice(state, currentPrice) {
            state.currentPrice = currentPrice;
        },
        setLng(state, value) {
            state.actLng = value
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        setTokensExpiry(state, tokensExpiry) {
            state.tokensExpiry = tokensExpiry;
        },
        setUser(state, user) {
            state.currentUser = user;
        },
        setDomainStep(state, domainStep) {
            state.domainStep = domainStep;
        },
        setOrderBill(state, value) {
            state.orderBill = value;
        },
        setPrice(state, value) {
            state.price = value;
        },
        setOptions(state, value) {
            state.options = value;
        },
        setPriceOptions(state, value) {
            state.priceOptions = value;
        },
        setElement(state, {key, value}) {
            state[key] = value;
        }
    }
})
