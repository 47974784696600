var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _vm.domain
            ? _c(
                "b-col",
                { staticClass: "page", attrs: { cols: "12" } },
                [
                  _c("b-row", { staticClass: "mb-4" }, [
                    _c("img", { attrs: { src: "" } }),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src:
                          "/images/graph-" +
                          (_vm.domain.id === 11 ? "iteration" : "disciplines") +
                          "_" +
                          _vm.lng +
                          ".svg",
                        alt: ""
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-row", { staticClass: "titre mb-4" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("domaines." + _vm.domain.title)
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.domain.topics, function(topic) {
                    return _c(
                      "b-row",
                      { key: topic.id, staticClass: "topic" },
                      [
                        _c("b-col", {
                          staticClass: "titre",
                          attrs: { sm: "12", md: "3" },
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("domaines." + topic.title))
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-row",
                              _vm._l(topic.examples, function(example) {
                                return _c(
                                  "b-col",
                                  {
                                    key: example.id,
                                    staticClass: "mb-3",
                                    attrs: { sm: "12", md: "4" }
                                  },
                                  [
                                    _vm.checkAccessLink(example)
                                      ? _c("router-link", {
                                          attrs: {
                                            to: {
                                              name: "calcul",
                                              params: {
                                                slug: example.title,
                                                id: example.id,
                                                lng: _vm.lng
                                              }
                                            }
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "<span" +
                                                (example.isFree
                                                  ? " class=free"
                                                  : "") +
                                                ">&bull; <strong>" +
                                                (example.isFree
                                                  ? "FREE "
                                                  : "") +
                                                "</strong></span>" +
                                                _vm.$t(
                                                  "examples." + example.title
                                                )
                                            )
                                          }
                                        })
                                      : _c("div", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.bottom",
                                              value: {
                                                customClass: "my-tooltip"
                                              },
                                              expression:
                                                "{ customClass: 'my-tooltip' }",
                                              modifiers: {
                                                hover: true,
                                                bottom: true
                                              }
                                            }
                                          ],
                                          staticClass: "text-primary",
                                          attrs: {
                                            title:
                                              _vm.$t("website.abominrequis") +
                                              (example.isFree
                                                ? "FREE "
                                                : _vm.account &&
                                                  _vm.account.lastOrder &&
                                                  _vm.account.lastOrder.price &&
                                                  _vm.account.lastOrder.price
                                                    .name === "solo"
                                                ? "PRO"
                                                : "SOLO")
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "<span" +
                                                (example.isFree
                                                  ? " class=free"
                                                  : "") +
                                                ">&bull; <strong>" +
                                                (example.isFree
                                                  ? "FREE "
                                                  : "") +
                                                "</strong></span>" +
                                                _vm.$t(
                                                  "examples." + example.title
                                                )
                                            )
                                          }
                                        })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [_c("Loading")],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }