<template>
    <b-modal
        id="modalSubscription"
        :title="$t('website.ajouter_utilisateur')"
        @ok="validSubmit"
    >
        <template #modal-header="{ close }">
            <h5>{{ $t('website.ajouter_utilisateur') }}</h5>
            <b-button-close size="sm" variant="outline-danger" @click="close()"/>
        </template>

        <template #default="{ hide }">
            <div v-if="error">
                <b-alert show variant="danger" v-html="$t('website.'+error)"/>
                <hr>
            </div>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form ref="subscriptionForm" @submit.stop.prevent="handleSubmit(onSubmit)">
                    <form-subscription :form="form"/>
                </b-form>
            </validation-observer>
        </template>

        <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">
                {{ $t('website.page_souscrire_bt_verifier') }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import FormSubscription from "./Subscription";
    import axios from "axios";

    export default {
        name: "ModalSubscription",
        props: ["getAccount", "account"],
        components: {FormSubscription},
        data() {
            return {
                error: null,
                form: {
                    lastname: null,
                    firstname: null,
                    job: null,
                    society: null,
                    address: null,
                    zipcode: null,
                    city: null,
                    country: {},
                    vatNumber: null,
                    phone: null,
                    email: null,
                    password: null,
                    confirmation: null,
                    code: null,
                },
            }
        },
        methods: {
            async validSubmit(bvModalEvt) {
                this.error = null
                bvModalEvt.preventDefault()
                const isValid = await this.$refs.observer.validate()
                if(isValid) {
                    this.onSubmit()
                }
            },
            onSubmit() {
                this.checkSubscription()
            },
            checkSubscription() {
                const refs = this.$refs.observer.refs;
                const url = this.$store.state.apiUrl + '/api/front/check_email?email=' + refs.email.value
                axios.get(
                    url
                ).then(
                    response => {
                        if(!response.data || response.data["hydra:totalItems"] !== 0) {
                            this.error = 'emailexistant'
                        } else {
                            this.setSubscription()
                        }
                    }
                )
            },
            setSubscription() {
                const refs = this.$refs.observer.refs;
                let params = {}
                Object.keys(refs).forEach(function(key){
                    let val = refs[key].value
                    if(key === "country") {
                        val = refs[key].value
                    }
                    params[key] = val
                });
                params.cgu = true

                this.addKeycloakUser(params)
            },

            addKeycloakUser(params) {
                const url = '/user/create-keycloak'
                axios.post(
                    url,
                    params
                ).then(
                    response => {
                        this.getKeycloakUser(params)
                    }
                )
            },
            getKeycloakUser(user) {
                const url = '/user/keycloak'
                let params = {}
                params.email = user.email
                axios.post(
                    url,
                    params
                ).then(
                    response => {
                        user.keycloakId = response.data
                        this.addUser(user)
                    }
                )
            },
            addUser(params) {
                const url = this.$store.state.apiUrl + '/api/clients'
                params.enterprise = this.account['@id']
                axios.post(
                    url,
                    params,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken,
                        }
                    }
                ).then(
                    response => {
                        this.$accessLogin(params.email, params.password)
                            .then(token => {
                                this.$bvModal.hide('modalSubscription')
                                this.getAccount()
                            })
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
