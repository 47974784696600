<template>
    <b-row class="calculLine mb-3 text-justify">
        <span v-html="$t('website.04_p01_supports')"/>
        <b-textarea class="mt-2 mb-2"/>
    </b-row>
</template>

<script>
    export default {
        name: "DroitSupports"
    }
</script>

<style scoped>

</style>
