<template>
    <tab-input
        url="criteria"
        title="Criteria"
        add-text="add a criterion"
        :make-toast="makeToast"
        title-notification="Criteria Notification"
        name= "Criterion"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Criteria-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
