var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "mt-4 mb-4" },
        [
          _c(
            "b-row",
            _vm._l(3, function(i) {
              return _c(
                "b-col",
                { key: i, staticClass: "blochome", attrs: { sm: "12" } },
                [
                  _c("div", {
                    staticClass: "titre",
                    domProps: { innerHTML: _vm._s(_vm.$t("home.titre" + i)) }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "texte",
                    domProps: { innerHTML: _vm._s(_vm.$t("home.texte" + i)) }
                  })
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "blocGreenBottom" },
        [_c("b-container", [_c("prices")], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }