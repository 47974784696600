<template>
    <div>
        <bottom-links/>
        <bottom-partners/>
    </div>
</template>

<script>
    import BottomLinks from "./BottomLinks";
    import BottomPartners from "./BottomPartners";

    export default {
        name: "FooterMain",
        components: {BottomLinks, BottomPartners},
    }
</script>

<style scoped>
</style>
