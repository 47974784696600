<template>
    <b-row class="mb-3 calculTotal">
        <b-col cols="12">
            <b-row>
                <b-col cols="8" class="text-right font-weight-bold">{{ $t('website.totalcalculhorstaxe') }} =</b-col>
                <b-col class="text-right pr-0 font-weight-bold">{{ total |formatNumber }} {{ currency }}</b-col>
            </b-row>
            <b-row>
                <b-col cols="8" class="text-right">{{ $t('website.tva') }}</b-col>
                <b-col class="text-right pr-0">{{ tva |formatNumber }} {{ currency }}</b-col>
            </b-row>
            <b-row>
                <b-col cols="8" class="text-right">{{ $t('website.totalcalculttc') }} =</b-col>
                <b-col class="text-right pr-0">{{ ttc |formatNumber }} {{ currency }}</b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "DroitTotalCell",
        props: ['currency', 'total', 'account'],
        computed: {
            tva() {
                if(this.total) {
                  let tva = 0
                  if(this.account.vatRate) {
                    tva = this.account.vatRate/100
                  }
                  return Math.round(100*tva*this.total)/100
                }
            },
            ttc() {
                if(this.total) {
                    return this.total + this.tva
                }
            }
        },
    }
</script>

<style scoped>
</style>
