<template>
    <component
        v-if="vue"
        v-bind:is="vue"
        name="page"
    />
</template>

<script>
    import AppAdmin from "./AppAdmin";
    import AppFront from "./AppFront";
    import {serverBus} from "../app";

    export default {
        name: "App",
        props: ['apiUrl', 'keycloakApiUrl', 'oauthUrl', 'stripeKey', 'vue'],
        components: {AppAdmin, AppFront},
        data() {
            return {
                timer: null,
                //clientKeycloak: "calkulator-admin",
            }
        },
        created() {
            this.$getConfig().then(
                data => {
                    this.$store.commit('setElement', {key: 'apiUrl', value: data.urlApi})
                    this.$store.commit('setElement', {key: 'keycloakApiUrl', value: data.urlKeycloakApi})
                    this.$store.commit('setElement', {key: 'realm', value: data.realm})
                    this.$store.commit('setElement', {key: 'oauthUrl', value: data.urlOauth})
                    this.$store.commit('setElement', {key: 'stripeKey', value: data.stripeKey})
                    this.initSession()
                }
            )
            serverBus.$on('logout', (bool) => {
                if(bool) {
                    this.logout()
                }
            })
        },
        methods: {
            initSession() {
                this.timer = setInterval(() => {
                    if(this.$store.state.tokensExpiry) {
                        let actualDate = new Date().toISOString()
                        let accessDiff = this.$moment(this.$store.state.tokensExpiry.accessExpiry).diff(actualDate, 'seconds')
                        let refreshDiff = this.$moment(this.$store.state.tokensExpiry.refreshExpiry).diff(actualDate, 'seconds')
                        //console.log(refreshDiff, accessDiff)
                        if(refreshDiff <= 10) {
                            this.logout()
                        }
                        if(accessDiff <= 0) {
                            this.logout()
                        }
                        if(accessDiff <= 10 ) {
                            this.$refresh().then(
                                data => {
                                    this.$setTokens(data)
                                    this.initSession()
                                }
                            )
                        }
                        return refreshDiff
                    }
                }, 6000)
            },
            logout() {
                clearTimeout(this.timer)
                this.$logout().then(
                    data => {
                        this.$setTokens(null)
                        this.$clearStore()
                        if(this.$router.currentRoute.name !== 'admin' && this.$router.currentRoute.name.lastIndexOf('admin') === 0) {
                            this.$router.push({name: 'admin'})
                        } else if(this.$router.currentRoute.name !== 'home') {
                            this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                        }
                    }
                )
            },
            /*initSession() {
                this.timer = setInterval(() => {
                    if(this.$store.state.tokensExpiry) {
                        let actualDate = new Date().toISOString()
                        let accessDiff = this.$moment(this.$store.state.tokensExpiry.accessExpiry).diff(actualDate, 'seconds')
                        let refreshDiff = this.$moment(this.$store.state.tokensExpiry.refreshExpiry).diff(actualDate, 'seconds')
                        if(refreshDiff <= 10) {
                            this.logout()
                        }
                        if(accessDiff <= 10 ) {
                            this.refresh()
                        }
                    }
                }, 6000)
            },*/
            /*accessLogin(username, password) {
                const tokenUrl = this.$store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/token'

                axios.post(
                    tokenUrl,
                    querystring.stringify({
                        grant_type: 'password',
                        client_id: this.clientKeycloak,
                        username: username,
                        password: password,
                    }),
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(
                    response => {
                        this.setTokens(response.data)
                        this.getUserinfo()

                    }
                )
            },*/
            /*getUserinfo() {
                const userinfoUrl = this.$store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/userinfo'

                axios.get(
                    userinfoUrl,
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.$store.commit('setUser', response.data)
                        if(this.vue !== 'AppAdmin') {
                            this.checkUser(response.data)
                        }
                    }
                )
            },*/
            /*checkUser(user) {
                const url = this.$store.state.apiUrl + "/api/clients?email=" + user.email
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(response => {
                    this.checkSession(response.data["hydra:member"][0])
                }
                ).catch(error => {
                    this.logout()
                })
            },*/
            /*checkSession(client) {
                const url = this.$store.state.keycloakApiUrl + "/api/users/sessions/" + client.keycloakId
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        if(response.data.length > 1) {
                            this.logout()
                        }
                    }
                ).catch(error => { this.logout() })
            },*/
            /*refresh() {
                const tokenUrl = this.$store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/token'

                axios.post(
                    tokenUrl,
                    querystring.stringify({
                        grant_type: 'refresh_token',
                        client_id: this.clientKeycloak,
                        refresh_token: this.$store.state.refreshToken
                    }),
                    {
                        'headers': {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                ).then(
                    response => {
                        this.setTokens(response.data)

                    }
                ).catch(
                    error => {
                        this.logout()
                    }
                )
            },*/
            /*logout() {
                clearTimeout(this.timer)
                if(!this.$store.state.refreshToken) {
                    this.clearStore()
                    if(this.vue !== 'AppAdmin') {
                        this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                    }
                    return false
                }
                const logoutUrl = this.$store.state.oauthUrl + '/auth/realms/Calkulator/protocol/openid-connect/logout'
                axios.post(
                    logoutUrl,
                    querystring.stringify({
                        client_id: this.clientKeycloak,
                        refresh_token: this.$store.state.refreshToken,
                    }),
                ).then(
                    response => {
                        this.setTokens(null)
                        this.clearStore()
                        if(this.vue !== 'AppAdmin') {
                            this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                        } else {
                            //this.$router.push({name: 'admin'})
                        }
                    }
                )
            },*/
            /*clearStore() {
                this.$store.commit('setUser', null)
                this.$store.commit('setAccessToken', null)
                this.$store.commit('setRefreshToken', null)
                this.$store.commit('setTokensExpiry', null)
                this.$store.commit('setCurrentPrice', null)
                this.$store.commit('setOrderBill', null)
                this.$store.commit('setElement', {key: 'calcul', value: null})
            },*/
            /*setTokens(data) {
                this.$store.commit('setAccessToken', data ? data.access_token : null)
                this.$store.commit('setRefreshToken', data ? data.refresh_token : null)
                let tokensExpiry = {}
                if(data) {
                    const tokenDate = new Date();
                    tokensExpiry = {
                        tokenDate: tokenDate.toISOString(),
                        accessExpiry: this.$moment(tokenDate.toISOString()).add(data.expires_in, 'seconds').toISOString(),
                        refreshExpiry: this.$moment(tokenDate.toISOString()).add(data.refresh_expires_in, 'seconds').toISOString(),
                    }
                }
                this.$store.commit('setTokensExpiry', data ? tokensExpiry : null)
            }*/
        },
    }
</script>

<style scoped>

</style>
