var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "col-sm-12 tarifs" }, [
    _c(
      "tbody",
      _vm._l(_vm.options, function(option, index) {
        return option.state
          ? _c("tr", [
              _c(
                "td",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1", class: option.color },
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c("i", { class: "icon-ICO" + option.icon })
                      ]),
                      _vm._v(" "),
                      _c("b-col", { staticClass: "text-left h-100" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("tarifs." + option.name))
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.priceOptions &&
                      _vm.priceOptions[_vm.price.id + "_" + option.id]
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "3" }
                            },
                            [
                              option.type === "name"
                                ? _c("span", {
                                    class: option.isBold
                                      ? "font-weight-bold"
                                      : "",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "tarifs." +
                                            _vm.priceOptions[
                                              _vm.price.id + "_" + option.id
                                            ]["value"]
                                        )
                                      )
                                    }
                                  })
                                : option.type === "state"
                                ? _c("span", [
                                    _vm.priceOptions[
                                      _vm.price.id + "_" + option.id
                                    ]["value"]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "icon-ICOcheck"
                                          })
                                        ])
                                      : _c("span", [_vm._v("-")])
                                  ])
                                : _c(
                                    "span",
                                    {
                                      class: option.isBold
                                        ? "font-weight-bold"
                                        : ""
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.priceOptions[
                                              _vm.price.id + "_" + option.id
                                            ]["value"]
                                          ) +
                                          "\n                        "
                                      ),
                                      option.type === "amount"
                                        ? _c("span", [_vm._v(" €")])
                                        : _vm._e()
                                    ]
                                  ),
                              _vm._v(" "),
                              option.type === "name" && _vm.domainSelected
                                ? _c("div", {
                                    staticClass: "font-weight-bold text-danger",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "domaines." + _vm.domainSelected.title
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        : _c(
                            "b-col",
                            {
                              staticClass: "text-center font-weight-bold",
                              attrs: { cols: "3" }
                            },
                            [_vm._v("-")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }