<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="user">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-container class="page" v-if="example && account">
                <b-row class="titre mb-2">
                    <div v-html="$t('domaines.' + example.topic.domain.title)"/>
                </b-row>
                <b-row class="stitre font-weight-bold">
                    <div v-html="$t('domaines.' + example.topic.title)"/>
                </b-row>
                <b-row class="mb-2">
                    <div v-html="$t('examples.' + example.title)"/>
                </b-row>
                <b-row class="mt-4">
                    <b-col sm="12" md="6" class="calculLine">
                        <b-row>
                            <b-col><div class="font-weight-bold" v-html="$t('website.totalhonoraires')"/></b-col>
                            <b-col class="text-right">{{ totalHonoraires |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}</b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <droit-exploitation
                    :total-honoraires="totalHonoraires"
                    name="exploitation"
                />
                <droit-option
                    name="option"
                />
                <b-row>
                    <b-col class="pl-0 pr-0"><hr/></b-col>
                </b-row>

                <b-row v-if="account && account.lastOrder && account.lastOrder.price && account.lastOrder.price.name === 'free'">
                    <b-col><span v-html="$t('website.04_p03_id_redevance')" class="text-danger"/></b-col>
                </b-row>

                <droit-redevance
                    name="redevance"
                    v-else
                />
            </b-container>
            <bottom-actions
                :button-text="$t('website.04_p02_bt_devisforfait')"
                :set-action="setAction"
                v-if="account && account.lastOrder && account.lastOrder.price && account.lastOrder.price.name === 'free'"
            />
            <bottom-actions
                :button-text="$t('website.04_p02_bt_devisforfait')"
                :button-text2="$t('website.04_p03_bt_devisredevance')"
                :set-action="setAction"
                v-else
            />
        </b-form>
    </validation-observer>
</template>

<script>
    import BottomActions from "../../../components/footer/BottomActions";
    import DroitExploitation from "../../../components/front/droits/Exploitation";
    import DroitOption from "../../../components/front/droits/Option";
    import DroitRedevance from "../../../components/front/droits/Redevance";
    import {serverBus} from "../../../../app";

    export default {
        name: "Droits",
        components: {BottomActions, DroitExploitation, DroitOption, DroitRedevance},
        data() {
            return {
                account: null,
                totalHonoraires: 0,
            }
        },
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            user() {
                return this.$store.state.currentUser
            },
            example() {
                return this.$store.state.example
            },
        },
        mounted() {
            if(!this.$store.state.currentUser) {
                this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
            }
            this.$store.commit("setDomainStep", 4)
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    this.$checkAccessPage(this.account, this.example)
                }
            )
            this.checkCalculsHonoraires()
        },
        methods: {
            setAction(name) {
                this.buttonName = name
            },
            checkCalculsHonoraires() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul['honoraires']) {
                    this.totalHonoraires = 0
                    Object.keys(calcul['honoraires']).forEach((key, index) => {
                        const calculObject = calcul['honoraires'][index]
                        if(calculObject['hour']) {
                            this.totalHonoraires += calculObject['hour']*calculObject['rate']
                        }
                    })
                }
            },
            onSubmit() {
                let route
                if(this.buttonName === 'buttonText') {
                    route = 'devis'
                }
                if(this.buttonName === 'buttonText2') {
                    route = 'devis'
                }
                this.$router.push({name: route, params: {lng: this.$store.state.actLng}})
            },
        }
    }
</script>

<style scoped>

</style>
