var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _vm.user
        ? _c(
            "b-row",
            { staticClass: "mt-5 mb-5" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", {
                  staticClass: "titre mb-2",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("website.page_welcome_titre"))
                  }
                }),
                _vm._v(" "),
                _vm.price && _vm.price.name === "free"
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("website.page_welcome_confirmation_free")
                        )
                      }
                    })
                  : _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("website.page_welcome_confirmation")
                        )
                      }
                    })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }