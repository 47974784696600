var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account && _vm.totalExploitation > 0
    ? _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { sm: "12", md: "6" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pl-0" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: { change: _vm.changeOption },
                          model: {
                            value: _vm.hasOption,
                            callback: function($$v) {
                              _vm.hasOption = $$v
                            },
                            expression: "hasOption"
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "font-weight-bold pt-1",
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("website.04_p02_option"))
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { sm: "12", md: "6" } }),
          _vm._v(" "),
          _vm.hasOption
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  _vm._l(4, function(i) {
                    return _c("droit-cell", {
                      key: i,
                      attrs: {
                        index: i - 1,
                        name: _vm.name,
                        "model-h": _vm.models[i - 1],
                        currency: _vm.account.currency.symbol,
                        type: "LineOption",
                        total: _vm.totalExploitation
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c("droit-total-cell", {
                    attrs: {
                      currency: _vm.account.currency.symbol,
                      total: _vm.totalOption,
                      account: _vm.account
                    }
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }