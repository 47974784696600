var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-text",
        [
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { cols: "10" } }, [
                  _c("div", [_vm._v(_vm._s(_vm.client.email))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.client.password) + " ")])
                ]),
                _vm._v(" "),
                _c("b-col", [
                  _vm.client.keycloakId
                    ? _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c("b-icon-lock-fill", {
                            attrs: { variant: "primary" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ],
          _vm._v(" "),
          _c("div", { staticClass: "priceName" }, [
            _vm.client.lastOrder.price
              ? _c("span", [_vm._v(_vm._s(_vm.client.lastOrder.price.name))])
              : _vm.client.lastOrder.example
              ? _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.client.lastOrder.example) +
                      "\n"
                  )
                ])
              : _c("span", [_c("pre", [_vm._v(_vm._s(_vm.client))])])
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(
                          _vm.client.lastOrder.paymentDate,
                          "DD/MM/YYYY"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "2" } }, [_vm._v("=>")]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c(
                      "h6",
                      [
                        _c(
                          "b-badge",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.client.lastOrder.active
                                ? "success"
                                : "danger"
                            }
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.client.lastOrder.validationDate,
                                    "DD/MM/YYYY"
                                  )
                                ) +
                                "\n                            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2" },
            _vm._l(_vm.client.orders, function(order, index) {
              return _c(
                "span",
                { key: order.id },
                [
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        size: "sm",
                        variant: index === 0 ? "primary" : ""
                      }
                    },
                    [_vm._v("N°" + _vm._s(order.id))]
                  ),
                  _vm._v(" "),
                  index < _vm.client.orders.length - 1
                    ? _c("span", [_vm._v("- ")])
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { href: "#", variant: "primary", size: "sm" },
          on: {
            click: function($event) {
              return _vm.detail(_vm.client)
            }
          }
        },
        [_vm._v("details")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }