import Vue from 'vue';
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import VueMoment from "vue-moment";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

Vue.use(VueI18n);
Vue.use(VueMoment);
Vue.use(VueRouter);

import store from './store';
import router from './route';
import plugin from './plugin';

import App from "./vue/App";
import AppAdmin from "./vue/AppAdmin";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import './styles/custom.scss';
import './styles/app.css';
import './styles/calkulator.css';

function localMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}
const i18n = new VueI18n({
    locale: "fr",
    messages: localMessages()
});

import numeral from 'numeral';
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0.00");
});

Vue.filter('slugify', function (value) {
    var slug = "";
    // Change to lower case
    var titleLower = value.toLowerCase();
    // Letter "e"
    slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
    // Letter "a"
    slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
    // Letter "o"
    slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
    // Letter "u"
    slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
    // Letter "d"
    slug = slug.replace(/đ/gi, 'd');
    // Trim the last whitespace
    slug = slug.replace(/\s*$/g, '');
    // Change whitespace to "-"
    slug = slug.replace(/\s+/g, '-');
    return slug;
})

Vue.filter('striphtml', function (value) {
    var div = document.createElement("div");
    div.innerHTML = value;
    var text = div.textContent || div.innerText || "";
    return text;
});

export const serverBus = new Vue();

new Vue({
    el: '#app',
    components: {App, AppAdmin},
    router,
    store,
    i18n
})
