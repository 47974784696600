<template>
    <b-row>
        <b-col sm="8" md="7" class="pr-md-0">
            <b-input-group size="sm" :prepend="(index+4) + '. '">
                <b-form-select
                    size="sm"
                    v-model="model"
                    @change="changeRate()"
                >
                    <b-form-select-option :value="null">{{ $t('cession.' + modelH) }}</b-form-select-option>
                    <b-form-select-option
                        v-for="factor in listFactors" :key="factor.id"
                        :value="factor"
                    >
                        {{ $t('cession.' + factor.title) }}
                    </b-form-select-option>

                </b-form-select>
            </b-input-group>
        </b-col>
        <b-col sm="4" md="2" class="pl-md-0">
            <b-input
                size="sm"
                v-model="rate"
                v-if="rate"
                @change="changeValue()"
            />
        </b-col>
        <b-col sm="12" md="3" class="text-right pt-2">{{ val |formatNumber }} {{ currency }}</b-col>
    </b-row>
</template>

<script>
    import factorsJson from '../../../../files/copyrightfactors.json'
    import {serverBus} from "../../../../app";

    export default {
        name: "SelectExploitation",
        props: ['index', 'modelH', 'currency', 'name', 'total'],
        data() {
            return {
                model: null,
                factors: factorsJson,
                options: null,
                listFactors: [],
                rate: null,
                val: 0,
            }
        },
        mounted() {
            this.factors.forEach((factor) => {
                if (factor.type.title === this.modelH) {
                    this.listFactors.push(factor)
                }
            })
            this.getRate()
            this.checkCalculsExploitation()
        },
        methods: {
            changeRate() {
                this.getRate()
                this.getValue()
                this.setCalculExploitation()
            },
            getRate() {
                if(this.model) {
                    this.rate = this.model.ratio
                } else {
                    this.rate = 0
                }
            },
            checkCalculsExploitation() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name] && calcul[this.name][this.index]) {
                    const calculObject = calcul[this.name][this.index]
                    if(calculObject['model']) {
                        this.model = calculObject['model']
                        this.rate = calculObject['rate']
                        this.getValue()
                    }
                }
            },
            changeValue() {
                this.getValue()
                this.setCalculExploitation()
            },
            getValue() {
                this.val = this.rate * this.total
            },
            setCalculExploitation() {
                let calcul = this.$store.state.calcul
                if(!calcul) {
                    calcul = {}
                }
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                if(!calcul[this.name][this.index]) {
                    calcul[this.name][this.index] = {}
                }
                calcul[this.name][this.index]['model'] = this.model
                calcul[this.name][this.index]['rate'] = this.rate
                calcul[this.name][this.index]['value'] = this.val

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                serverBus.$emit(this.name, true)
            },
        }
    }
</script>

<style scoped>

</style>
