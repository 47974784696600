var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account
    ? _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("div", { staticClass: "mb-3" }, [
            _c("span", {
              staticClass: "font-weight-bold",
              domProps: { innerHTML: _vm._s(_vm.$t("website.04_p03_titre")) }
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.$t("website.04_p03_titre2")) }
            })
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "b-row",
                { staticClass: "calculLine mb-3" },
                [
                  _c("b-col", { staticClass: "text-nowrap pr-0 mt-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("website.04_p03_prix")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c("b-input", {
                        attrs: { size: "sm" },
                        on: {
                          change: function($event) {
                            return _vm.changeValue()
                          }
                        },
                        model: {
                          value: _vm.price,
                          callback: function($$v) {
                            _vm.price = $$v
                          },
                          expression: "price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { staticClass: "text-nowrap pr-0 mt-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("website.04_p03_exemplaires")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c("b-input", {
                        attrs: { size: "sm" },
                        on: {
                          change: function($event) {
                            return _vm.changeValue()
                          }
                        },
                        model: {
                          value: _vm.numbers,
                          callback: function($$v) {
                            _vm.numbers = $$v
                          },
                          expression: "numbers"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { staticClass: "text-nowrap mt-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("website.04_p03_ca")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-col", { staticClass: "text-right mt-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm._f("formatNumber")(_vm.total)) +
                        " " +
                        _vm._s(
                          _vm.account.currency
                            ? _vm.account.currency.symbol
                            : "€"
                        ) +
                        "\n            "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-radio-group",
                        {
                          model: {
                            value: _vm.rights,
                            callback: function($$v) {
                              _vm.rights = $$v
                            },
                            expression: "rights"
                          }
                        },
                        [
                          _c(
                            "b-form-radio",
                            {
                              attrs: { size: "sm", value: "0" },
                              on: { change: _vm.changeRight }
                            },
                            [
                              _c("div", {
                                staticClass: "mt-1",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("website.04_p03_cession_exclusive")
                                  )
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-radio",
                            {
                              attrs: { value: "1", size: "sm" },
                              on: { change: _vm.changeRight }
                            },
                            [
                              _c("div", {
                                staticClass: "mt-1",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "website.04_p03_cession_nonexclusive"
                                    )
                                  )
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "mt-2", attrs: { sm: "12", md: "6" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.04_p03_redevance"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-col", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.04_p03_tranche"))
                      }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.tranches, function(tranche, i) {
                return _c("droit-cell", {
                  key: i,
                  staticClass: "mb-2",
                  attrs: {
                    index: i,
                    name: _vm.name,
                    "model-h": _vm.tranches,
                    currency: _vm.account.currency
                      ? _vm.account.currency.symbol
                      : "€",
                    type: "LineRedevance"
                  }
                })
              }),
              _vm._v(" "),
              _c("droit-total-cell", {
                attrs: {
                  currency: _vm.account.currency
                    ? _vm.account.currency.symbol
                    : "€",
                  total: _vm.totalRedevance,
                  account: _vm.account
                }
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("b-col"),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "5" } },
            [
              _c("droit-cell", {
                staticClass: "mb-2",
                attrs: {
                  index: 0,
                  name: "avance",
                  "model-h": _vm.avance,
                  currency: _vm.account.currency
                    ? _vm.account.currency.symbol
                    : "€",
                  type: "LineAvance",
                  total: _vm.totalRedevance
                }
              }),
              _vm._v(" "),
              _c("droit-supports", { attrs: { name: _vm.name } })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }