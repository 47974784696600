var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    [
      _c(
        "b-tr",
        { staticClass: "firstLine" },
        [
          _c("b-td", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.title1) } })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("b-tr", [_c("b-td", { staticClass: "test" })], 1),
      _vm._v(" "),
      _c(
        "b-tr",
        { staticClass: "secondLine" },
        [
          _c("b-td", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.title2) } })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }