var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-3 calculTotal" },
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right font-weight-bold",
                  attrs: { cols: "8" }
                },
                [_vm._v(_vm._s(_vm.$t("website.totalcalculhorstaxe")) + " =")]
              ),
              _vm._v(" "),
              _c("b-col", { staticClass: "text-right pr-0 font-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm._f("formatNumber")(_vm.total)) +
                    " " +
                    _vm._s(_vm.currency)
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-right", attrs: { cols: "8" } }, [
                _vm._v(_vm._s(_vm.$t("website.tva")))
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "text-right pr-0" }, [
                _vm._v(
                  _vm._s(_vm._f("formatNumber")(_vm.tva)) +
                    " " +
                    _vm._s(_vm.currency)
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-right", attrs: { cols: "8" } }, [
                _vm._v(_vm._s(_vm.$t("website.totalcalculttc")) + " =")
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "text-right pr-0" }, [
                _vm._v(
                  _vm._s(_vm._f("formatNumber")(_vm.ttc)) +
                    " " +
                    _vm._s(_vm.currency)
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }