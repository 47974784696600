<template>
    <b-row class="mt-4" v-if="account">
        <div class="mb-3">
            <span class="font-weight-bold" v-html="$t('website.04_p01_titre')"/>
            <span v-html="$t('website.04_p01_titre2')"></span>
        </div>
        <b-col sm="12" md="6">
            <droit-cell
                :index="i"
                :model-h="type.title"
                :name="name"
                :total="totalHonoraires"
                :currency="account.currency ? account.currency.symbol : '€'"
                type="LineExploitation"
                v-for="(type, i) in types" :key="type.id"
            />
            <droit-total-cell
                :currency="account.currency ? account.currency.symbol : '€'"
                :total="totalExploitation"
                :account="account"
            />
        </b-col>
        <b-col/>
        <b-col sm="12" md="5">
            <droit-supports
                :name="name"
            />
        </b-col>
    </b-row>
</template>

<script>
    import DroitCell from "./DroitCell";
    import DroitSupports from "./DroitSupports";
    import DroitTotalCell from "./DroitTotalCell";
    import typesJson from '../../../../files/copyrighttypes.json'
    import {serverBus} from "../../../../app";

    export default {
        name: "Exploitation",
        components: {DroitCell, DroitSupports, DroitTotalCell},
        props: ['totalHonoraires', 'name'],
        data() {
            return {
                account: null,
                types: typesJson,
                models: [
                    'exclusivite',
                    'espace',
                    'duree',
                    'etendue'
                ],
                totalExploitation: 0
            }
        },
        mounted() {
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                }
            )
            this.getCalculsExploitation()
            serverBus.$on(this.name, (isChanged) => {
                if(isChanged) {
                    this.getCalculsExploitation()
                    serverBus.$emit(this.name, false)
                }
            })
        },
        methods: {
            getCalculsExploitation() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name]) {
                    this.totalExploitation = 0
                    Object.keys(calcul[this.name]).forEach((key) => {
                        const calculObject = calcul[this.name][key]
                        if(calculObject['model']) {
                            this.totalExploitation += calculObject['rate']*this.totalHonoraires
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
