<template>
    <b-container fluid>
        <h5><b-badge variant="primary">Price options</b-badge></h5>
        <b-button variant="primary" disabled v-if="!priceOptions">
            <b-spinner small type="grow"></b-spinner>
            Loading...
        </b-button>
        <div v-else>
            <b-row>
                <b-col></b-col>
                <b-col v-for="price in prices" :key="price.id" class="name">
                    {{ price.name }}
                </b-col>
            </b-row>
            <b-row v-for="option in options" :key="option.id" class="mb-1">
                <b-col class="mr-1" :class="option.color"><i :class="'icon-ICO'+option.icon"/><span class="ml-2" v-html="$t('tarifs.'+option.name)"/></b-col>
                <b-col v-for="price in prices" :key="price.id" class="text-center mr-1" :class="option.color">
                    <b-form-input
                        :value="(priceOptions[price.id+'_'+option.id]) ? priceOptions[price.id+'_'+option.id]['value'] : ''"
                        size="sm"
                        @change="saveOption($event, price.id, option.id, option.type)"
                        :type="(option.type === 'amount' || option.type === 'number') ? 'number' : 'text' "
                        v-if="option.type !== 'state'"
                    />
                    <b-form-checkbox
                        switch size="sm"
                        :checked="(priceOptions[price.id+'_'+option.id] && priceOptions[price.id+'_'+option.id]['value'] === true) ? 'true' : 'false'"
                        @change="saveOption($event, price.id, option.id, option.type)"
                        v-else
                    >

                    </b-form-checkbox>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import axios from "axios";
    import querystring from "querystring";

    export default {
        name: "Price-Options",
        props: ["makeToast"],
        data() {
            return {
                prices: null,
                options: null,
                priceOptions: {},
                newPriceOption: {},
            }
        },
        mounted() {
            this.getPrices()
        },
        methods: {
            getPrices() {
                const url = this.$store.state.apiUrl + '/api/prices?pagination=false&state=true'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.prices = response.data["hydra:member"]
                        this.getOptions()
                    }
                )
            },
            getOptions() {
                const url = this.$store.state.apiUrl + '/api/options?pagination=false&state=true'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.options = response.data["hydra:member"]
                        this.getPriceOptions()
                    }
                )
            },
            getPriceOptions() {
                const url = this.$store.state.apiUrl + '/api/price_options?pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.priceOptions = {}
                        let self = this
                        response.data["hydra:member"].forEach(data => {
                            this.priceOptions[data.price.id + '_' + data.option.id] = {}
                            this.priceOptions[data.price.id + '_' + data.option.id]['id'] = data.id
                            if(data.amount) {
                                this.priceOptions[data.price.id + '_' + data.option.id]['value'] = data.amount
                            }
                            if(data.number) {
                                this.priceOptions[data.price.id + '_' + data.option.id]['value'] = data.number
                            }
                            if(data.name) {
                                this.priceOptions[data.price.id + '_' + data.option.id]['value'] = data.name
                            }
                            if(data.state) {
                                this.priceOptions[data.price.id + '_' + data.option.id]['value'] = data.state
                            }
                        })
                        this.generate()
                    }
                )
            },
            saveOption(value, price, option, type) {
                this.newPriceOption = {}
                this.newPriceOption.price = '/api/prices/' + price
                this.newPriceOption.option = '/api/options/' + option
                if (type === 'amount' || type === 'number') {
                    this.newPriceOption['amount'] = 1 * value
                }else if (type === 'state') {
                    this.newPriceOption[type] = (value) ? true : false
                } else {
                    this.newPriceOption[type] = value
                }
                if(!this.priceOptions[price + '_' + option]) {
                    this.create()
                } else {
                    this.newPriceOption.id = this.priceOptions[price + '_' + option]['id']
                    this.save()
                }
            },
            save() {
                const url = this.$store.state.apiUrl + '/api/price_options/' + this.newPriceOption.id
                axios.put(
                    url,
                    this.newPriceOption,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.postHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(this.newPriceOption.price + ' ' + this.newPriceOption.option + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create() {
                if(this.newPriceOption.price != null && this.newPriceOption.option != null) {
                    const url = this.$store.state.apiUrl + '/api/price_options'
                    axios.post(
                        url,
                        this.newPriceOption,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.makeToast(this.newPriceOption.price + ' ' + this.newPriceOption.option + ' has been created', 'success', this.titleNotification)
                        }
                    )
                }
            },
            generate() {
                const url = '/generate/file/price-options'
                axios.post(
                    url,
                    querystring.stringify(
                        {
                            elements: JSON.stringify(this.priceOptions)
                        }
                    )
                ).then(
                    response => {
                    }
                )
            },
        }
    }
</script>

<style scoped>
</style>
