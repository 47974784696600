<template>
    <b-row
        class="col-sm-12 col-md-8"
    >
        <b-col cols="9">
            <b-form-group label-cols="2" label-size="sm" :label="language.code" :label-for="'input-sm'+language.id+'-'+contentKey.id">
                <b-form-textarea v-model="content.text" v-if="content" :id="'input-sm'+language.id+'-'+contentKey.id" size="sm"></b-form-textarea>
                <b-form-textarea v-model="newContent.text" v-else  :id="'input-sm'+language.id+'-'+contentKey.id" size="sm"></b-form-textarea>
            </b-form-group>
        </b-col>
        <b-col class="text-right mt-1">
            <b-button size="sm" variant="primary" @click="content ? save(content, language) : create(language)">save</b-button>
            <b-button size="sm" variant="danger" @click="del(content)" v-if="content">delete</b-button>
        </b-col>
    </b-row>
</template>

<script>
    import axios from "axios";
    import querystring from "querystring";

    export default {
        name: "Translation-Admin",
        props: ["language", "contentKey", "makeToast"],
        data() {
            return {
                content: null,
                newContent: {
                    text: null,
                    contentKey: null,
                    language: null,
                },
                titleNotification: "Translation Notification"
            }
        },
        mounted() {
            this.content = null
            this.getContent()
        },
        methods: {
            getContent() {
                const url = this.$store.state.apiUrl + '/api/contents?contentKey=' + this.contentKey["@id"] + '&language=' + this.language["@id"]
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        const datas = response.data["hydra:member"]
                        this.content = (datas.length > 0) ? datas[0] : null
                    }
                )

            },
            save(contentKey, language) {
                const url = this.$store.state.apiUrl + contentKey["@id"]
                axios.patch(
                    url,
                    contentKey,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.generate()
                        this.makeToast(this.contentKey.name + ' ' + this.language.code + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
            create(language) {
                if(this.newContent.text != null) {
                    this.newContent.contentKey = this.contentKey["@id"]
                    this.newContent.language = this.language["@id"]
                    const url = this.$store.state.apiUrl + '/api/contents'
                    axios.post(
                        url,
                        this.newContent,
                        {
                            'headers': {
                                'Content-Type': this.$store.state.postHeader,
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(
                        response => {
                            this.getContent()
                            this.generate()
                            this.makeToast(this.contentKey.name + ' ' + this.language.code + ' has been created', 'success', this.titleNotification)
                            this.newContent.name = null
                        }
                    )
                }
            },
            del(contentKey) {
                this.$bvModal.msgBoxConfirm('Are you sure?')
                    .then(value => {
                        if (value) {
                            const url = this.$store.state.apiUrl + contentKey["@id"]
                            axios.delete(
                                url,
                                {
                                    'headers': {
                                        'Authorization': 'Bearer ' + this.$store.state.accessToken
                                    }
                                }
                            ).then(
                                response => {
                                    this.getContent()
                                    this.generate()
                                    this.makeToast(contentKey.name + ' has been deleted', 'warning', this.titleNotification)
                                }
                            )
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
            },
            generate() {
                let translation = {
                    test: 'essai'
                }
                axios.get(
                    this.$store.state.apiUrl + '/api/content-translations?pagination=false&language='+this.language["@id"],
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        const url = '/generate/translations'
                        axios.post(
                            url,
                            querystring.stringify(
                            {
                                    language: this.language.code,
                                    translations: JSON.stringify(response.data["hydra:member"])
                                }
                            )
                        ).then(
                            response => {
                            }
                        )
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
