<template>
    <b-container class="mt-3">
        <b-row v-for="language in languages" :key="language.id">
            <translation :language="language" :content-key="contentKey" :make-toast="makeToast"/>
        </b-row>
    </b-container>
</template>

<script>
    import axios from "axios";
    import Translation from "./Translation";

    export default {
        name: "Translations-Admin",
        props: ["contentKey", "makeToast"],
        components: {Translation},
        data() {
            return {
                languages: null,
                newContent: {
                    text: null,
                    contentKey: null,
                    language: null,
                },
            }
        },
        mounted() {
            this.getLanguages()
        },
        methods: {
            getLanguages() {
                const url = this.$store.state.apiUrl + '/api/languages?state=true'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.languages = response.data["hydra:member"]
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
