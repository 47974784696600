var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "currencies",
      title: "Currencies",
      "add-text": "add a currency",
      "make-toast": _vm.makeToast,
      "title-notification": "Currency Notification",
      name: "Currency",
      "generate-file": "currencies"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }