var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _vm.domains
          ? _c(
              "div",
              { staticClass: "col-sm-11 col-md-12 text-center" },
              [
                _c(
                  "span",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "discipline", params: { lng: _vm.lng } },
                          "active-class": "active"
                        }
                      },
                      [
                        _c("b-icon-list-ul", {
                          staticClass: "mr-1 pt-1",
                          attrs: { scale: "2.5" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(" | ")])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.domains, function(domain, index) {
                  return _c(
                    "span",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "domaine",
                              params: {
                                slug: domain.title,
                                id: domain.id,
                                lng: _vm.lng
                              }
                            },
                            "active-class": "active"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("menu." + domain.title)) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      index < _vm.domains.length - 1
                        ? _c("span", [_vm._v(" | ")])
                        : _vm._e()
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }