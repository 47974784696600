<template>
    <tab-input
        url="discount_codes"
        title="Discount codes"
        add-text="add a code"
        :make-toast="makeToast"
        title-notification="Discount Code Notification"
        name="DiscountCode"
    />
</template>

<script>
    import TabInput from "../../../components/admin/TabInput";

    export default {
        name: "Discount-Codes-Admin",
        props: ["makeToast"],
        components: {TabInput},
    }
</script>

<style scoped>
</style>
