var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("top-header"),
      _vm._v(" "),
      _c("menu-header"),
      _vm._v(" "),
      _vm.domains ? _c("menu-domains") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }