<template>
    <div class="blocGreen" v-if="currentStep">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col
                    cols="4"
                    sm="4"
                    md="2"
                    v-for="(step, index) in steps"
                    :key="index"
                    class="text-center navig"
                    :class="(currentStep && currentStep === index+1) ? 'actif' : ''"
                >
                    <router-link
                        :to="{ name: step.url, params: {lng: lng, slug: step.slug, id: step.id} }"
                        active-class="active"
                        v-if="index < currentStep"
                    >
                        <step-domain :index="index+1" :step="step.name"/>
                    </router-link>
                    <div v-else>
                        <step-domain :index="index+1" :step="step.name"/>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import StepDomain from "./StepDomain";
    import {serverBus} from "../../../app";

    export default {
        name: "MenuDomains",
        data() {
            return {
                steps: [
                    {name: 'choixdomaine', url: 'discipline'},
                    {
                        name: 'definirprojet',
                        url: 'domaine',
                        slug: '',
                        id: ''
                    },
                    {
                        name: 'ck2_menu_honoraires',
                        url: 'calcul',
                        slug: '',
                        id: ''
                    },
                    {name: 'ck2_menu_droits', url: 'droits'},
                    {name: 'redigerdevis'},
                ]
            }
        },
        components: {StepDomain},
        watch: {
            $route(to, from) {
                this.getSteps()
            },
        },
        computed: {
            currentStep() {
                return this.$store.state.domainStep
            },
            lng() {
                return this.$store.state.actLng
            },
        },
        mounted() {
            serverBus.$on('domain', (domain) => {
                //console.log(domain)
                if(domain) {
                    this.steps[1].slug = domain.slug
                    this.steps[1].id = domain.id
                }
            })
        },
        methods: {
            getSlug(type) {
                return (type === 'domain') ? this.$store.state.domain.slug : this.$store.state.example.title
            },
            getId(type) {
                return (type === 'domain') ? this.$store.state.domain.id : this.$store.state.example.id
            },
            getSteps() {
               /* this.steps = [
                    {name: 'choixdomaine', url: 'discipline'},
                    {
                        name: 'definirprojet',
                        url: 'domaine',
                        slug: this.getSlug('domain'),
                        id: this.getId('domain')
                    },
                    {
                        name: 'ck2_menu_honoraires',
                        url: 'calcul',
                        slug: this.getSlug('example'),
                        id: this.getId('example')
                    },
                    {name: 'ck2_menu_droits', url: 'droits'},
                    {name: 'redigerdevis'},
                ]*/
            }
        }
    }
</script>

<style scoped>

</style>
