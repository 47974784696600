var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v(_vm._s(_vm.index))]),
    _vm._v(" "),
    _c("div", {
      domProps: { innerHTML: _vm._s(_vm.$t("website." + _vm.step)) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }