<template>
    <div>
        <b-row>
            <b-col cols="2">
                <b-form-select v-model="selected" size="sm" class="mt-3 mb-4" v-if="domains">
                    <b-form-select-option :value="null">sort by domain</b-form-select-option>
                    <b-form-select-option v-for="domain in domains" :value="domain.id" :key="domain.id" :style="'color: '+domain.color">
                        {{ $t('domaines.'+domain.title) }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col
                cols="12" md="6" sm="12" lg="3"
                class="mb-4"
                v-for="(topic, index) in topics"
                :key="topic.id"
                v-if="selected && selected === topic.domain.id"
                :style="'color: '+topic.domain.color"
            >
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(setTopic(topic))">
                        <b-card
                        >
                            <b-card-sub-title v-html="$t('domaines.'+topic.title)"/>
                            <b-card-text>
                                <b-input-group size="sm" class="mt-2">
                                    <b-form-input v-model="topic.title"/>
                                    <b-input-group-append>
                                        <b-button variant="primary" type="submit">save</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-card-text>
                        </b-card>
                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from "axios";
    import domainsJson from '../../../../files/domains.json'

    export default {
        name: "Topics-Admin",
        props:["makeToast"],
        data() {
            return {
                selected: null,
                domains: domainsJson,
                topics: null,
                titleNotification: 'Topic Notification',
            }
        },
        mounted() {
            this.getTopics()
        },
        methods: {
            getTopics() {
                const url = this.$store.state.apiUrl + '/api/topics?pagination=false'
                axios.get(
                    url,
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.topics = response.data["hydra:member"]
                    }
                )
            },
            setTopic(topic) {
                let updateTopic = {}
                updateTopic.title = topic.title
                const url = this.$store.state.apiUrl + topic["@id"]
                axios.patch(
                    url,
                    updateTopic,
                    {
                        'headers': {
                            'Content-type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.makeToast(topic.title + ' has been modified', 'info', this.titleNotification)
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
