var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "copyright_factors",
      title: "Copyright factors",
      "add-text": "add a copyright factor",
      "make-toast": _vm.makeToast,
      "title-notification": "Factor Notification",
      name: "CopyrightFactor",
      "generate-file": "copyrightfactors"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }