var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.partners && _vm.partners.length > 0
    ? _c(
        "b-container",
        { staticClass: "text-center mt-5 mb-5" },
        _vm._l(_vm.partners, function(partner) {
          return _c("a", { attrs: { href: partner.url, target: "_blank" } }, [
            _c("img", {
              attrs: {
                src:
                  _vm.$store.state.apiUrl +
                  "/images/partners/" +
                  partner.image.filePath,
                alt: ""
              }
            })
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }