var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "col-sm-12 col-md-6 col-lg-4" },
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }, [_vm._v("Name ")]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _vm.element
                        ? _c(
                            "b-input-group",
                            {
                              attrs: {
                                size: "sm",
                                append: _vm.$t("cession." + _vm.element.title),
                                maxlength: "30"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { size: "sm" },
                                model: {
                                  value: _vm.element.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.element, "title", $$v)
                                  },
                                  expression: "element.title"
                                }
                              })
                            ],
                            1
                          )
                        : _c("b-form-input", {
                            attrs: { size: "sm", maxlength: "30" },
                            model: {
                              value: _vm.newElement.title,
                              callback: function($$v) {
                                _vm.$set(_vm.newElement, "title", $$v)
                              },
                              expression: "newElement.title"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }, [_vm._v("Priority ")]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _vm.element
                        ? _c("b-form-input", {
                            attrs: { size: "sm", maxlength: "30" },
                            model: {
                              value: _vm.element.priority,
                              callback: function($$v) {
                                _vm.$set(_vm.element, "priority", $$v)
                              },
                              expression: "element.priority"
                            }
                          })
                        : _c("b-form-input", {
                            attrs: { size: "sm", maxlength: "30" },
                            model: {
                              value: _vm.newElement.priority,
                              callback: function($$v) {
                                _vm.$set(_vm.newElement, "priority", $$v)
                              },
                              expression: "newElement.priority"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.element ? _vm.save(_vm.element) : _vm.create()
                            }
                          }
                        },
                        [_vm._v("save")]
                      ),
                      _vm._v(" "),
                      _vm.element
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.del(_vm.element)
                                }
                              }
                            },
                            [_vm._v("delete")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }