<template>
    <b-row class="mt-3" v-if="account">
        <div class="mb-3">
            <span class="font-weight-bold" v-html="$t('website.04_p03_titre')"/> <span v-html="$t('website.04_p03_titre2')"/>
        </div>
        <b-col sm="12">
            <b-row class="calculLine mb-3">
                <b-col class="text-nowrap pr-0 mt-2">
                    {{ $t('website.04_p03_prix') }}
                </b-col>
                <b-col>
                    <b-input
                        size="sm"
                        v-model="price"
                        @change="changeValue()"
                    />
                </b-col>
                <b-col class="text-nowrap pr-0 mt-2">
                    {{ $t('website.04_p03_exemplaires') }}
                </b-col>
                <b-col>
                    <b-input
                        size="sm"
                        v-model="numbers"
                        @change="changeValue()"
                    />
                </b-col>
                <b-col class="text-nowrap mt-2">
                    {{ $t('website.04_p03_ca') }}
                </b-col>
                <b-col class="text-right mt-2">
                    {{ total |formatNumber }} {{ account.currency ? account.currency.symbol : '€' }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-radio-group
                        v-model="rights"
                    >
                        <b-form-radio
                            size="sm"
                            value="0"
                            @change="changeRight"
                        >
                            <div v-html="$t('website.04_p03_cession_exclusive')" class="mt-1"/>
                        </b-form-radio>
                        <b-form-radio
                            value="1"
                            size="sm"
                            @change="changeRight"
                        >
                            <div v-html="$t('website.04_p03_cession_nonexclusive')" class="mt-1"/>
                        </b-form-radio>
                    </b-form-radio-group>
                </b-col>
            </b-row>
        </b-col>
        <b-col sm="12" md="6" class="mt-2">
            <b-row>
                <b-col cols="3"><span v-html="$t('website.04_p03_redevance')"/></b-col>
                <b-col><span v-html="$t('website.04_p03_tranche')"/></b-col>
            </b-row>
            <droit-cell
                :index="i"
                :name="name"
                :model-h="tranches"
                :currency="account.currency ? account.currency.symbol : '€'"
                class="mb-2"
                v-for="(tranche, i) in tranches" :key="i"
                type="LineRedevance"
            />
            <droit-total-cell
                :currency="account.currency ? account.currency.symbol : '€'"
                :total="totalRedevance"
                :account="account"
            />
        </b-col>
        <b-col/>
        <b-col sm="12" md="5">
            <droit-cell
                :index="0"
                name="avance"
                :model-h="avance"
                :currency="account.currency ? account.currency.symbol : '€'"
                class="mb-2"
                type="LineAvance"
                :total="totalRedevance"
            />
            <droit-supports
                :name="name"
            />
        </b-col>
    </b-row>
</template>

<script>
    import DroitCell from "./DroitCell";
    import DroitSupports from "./DroitSupports";
    import DroitTotalCell from "./DroitTotalCell";
    import {serverBus} from "../../../../app";

    export default {
        name: "Redevance",
        props: ['name'],
        components: {DroitCell, DroitSupports, DroitTotalCell},
        data() {
            return {
                account: null,
                tranches: [
                    {rate0: 10, rate1: 7, ca: 0},
                    {rate0: 10, rate1: 7, ca: 0},
                    {rate0: 10, rate1: 7, ca: 0},
                    {rate0: 10, rate1: 7, ca: 0},
                    {rate0: 7, rate1: 5, ca: 0},
                    {rate0: 5, rate1: 3, ca: 0},
                    {rate0: 3, rate1: 2, ca: 0},
                ],
                rateRedevance: 'rate0',
                ca: null,
                avance: [
                    {key: '04_p03_avance', rate: 9}
                ],
                price: null,
                numbers: null,
                rights: 0,
                totalRedevance: 0,
            }
        },
        mounted() {
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    this.account.currency.quoteSlice.split('*').forEach((ca, i) => {
                        this.tranches[i]['ca'] = ca
                    })
                }
            )
            this.checkCalculsRedevance()
            this.getCalculsRedevance()
            serverBus.$on('redevanceCalcul', (isChanged) => {
                if(isChanged) {
                    this.getCalculsRedevance()
                    serverBus.$emit('redevanceCalcul', false)
                }
            })
        },
        computed: {
            total() {
                return this.price * this.numbers
            },
        },
        methods: {
            checkCalculsRedevance() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name]) {
                    const calculObject = calcul[this.name]
                    if(calculObject['price']) {
                        this.price = calculObject['price']
                        this.numbers = calculObject['numbers']
                    }
                    if(calculObject['rights']) {
                        this.rights = calculObject['rights']
                        this.rateRedevance = "rate" + (1*this.rights)
                    }
                }
            },
            changeValue() {
                this.setCalculRedevance()
            },
            setCalculRedevance() {
                let calcul = this.$store.state.calcul
                if(!calcul) {
                    calcul = {}
                }
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                calcul[this.name]['price'] = this.price
                calcul[this.name]['numbers'] = this.numbers

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                serverBus.$emit(this.name, true)
            },
            changeRight() {
                let calcul = this.$store.state.calcul
                if(!calcul[this.name]) {
                    calcul[this.name] = {}
                }
                calcul[this.name]['rights'] = this.rights
                this.rateRedevance = "rate" + (1*this.rights)
                serverBus.$emit('rateRedevance', this.rateRedevance)
                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
            },
            getCalculsRedevance() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name]) {
                    this.totalRedevance = 0
                    Object.keys(calcul[this.name]).forEach((key) => {
                        const calculObject = calcul[this.name][key]
                        if(calculObject && calculObject['value']) {
                            this.totalRedevance += calculObject['value']
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
