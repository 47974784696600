var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-nowrap pr-1 pt-2" }, [
                _vm._v(_vm._s(_vm.$t("website." + _vm.title)))
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "pr-0" },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm", append: "%" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { size: "sm" },
                          on: {
                            change: function($event) {
                              return _vm.changeValue()
                            }
                          },
                          model: {
                            value: _vm.rate,
                            callback: function($$v) {
                              _vm.rate = $$v
                            },
                            expression: "rate"
                          }
                        },
                        _vm._l(19, function(i) {
                          return _c(
                            "b-form-select-option",
                            { key: i, attrs: { value: i + 1 } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(i + 1) +
                                  "\n                        "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("b-col", { staticClass: "text-right pt-2", attrs: { cols: "4" } }, [
        _vm._v(
          _vm._s(_vm._f("formatNumber")(_vm.value)) + " " + _vm._s(_vm.currency)
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }