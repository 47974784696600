var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account && _vm.country && _vm.group
    ? _c(
        "b-table-simple",
        { staticClass: "text-center", attrs: { responsive: "sm" } },
        [
          _c(
            "b-tbody",
            [
              _c(
                "b-tr",
                [
                  _c("b-td", { staticClass: "style1 font-weight-bold" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("countries." + _vm.country.code)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style1" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_delais"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style2" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_indep1"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style2" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_indep2"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style3" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_indep3"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style2" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_societe"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style2" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_specialiste"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("b-td", { staticClass: "style4" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_tauxmoy"))
                      }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tr",
                [
                  _c("b-td", { staticClass: "style1" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_gdesvilles"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    { staticClass: "style1", attrs: { rowspan: "2" } },
                    [
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.example.minDelay) +
                            " "
                        ),
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.$t("website.a")) }
                        }),
                        _vm._v(" " + _vm._s(_vm.example.maxDelay)),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("website.heures"))
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("website.03_tab_moyenne"))
                        }
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                    " + _vm._s(_vm.delay) + " "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("website.heures"))
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(5, function(i) {
                    return _c(
                      "b-td",
                      { key: "a" + i, staticClass: "style2" },
                      [
                        _c("rate-cell", {
                          attrs: {
                            rate: _vm.rateGV[i - 1],
                            group: _vm.group,
                            currency: _vm.account.currency
                              ? _vm.account.currency
                              : _vm.currencies[0],
                            example: _vm.example
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    { staticClass: "style4", attrs: { rowspan: "2" } },
                    [
                      _c("rate-cell", {
                        attrs: {
                          rate: _vm.moyenne,
                          group: _vm.group,
                          currency: _vm.account.currency
                            ? _vm.account.currency
                            : _vm.currencies[0],
                          example: _vm.example,
                          "has-bold": "true"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-tr",
                [
                  _c("b-td", { staticClass: "style1" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("website.03_tab_ptvilles"))
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._l(2, function(i) {
                    return _c(
                      "b-td",
                      { key: "b" + i, staticClass: "style2" },
                      [
                        _c("rate-cell", {
                          attrs: {
                            rate: _vm.ratePV[i - 1],
                            group: _vm.group,
                            currency: _vm.account.currency
                              ? _vm.account.currency
                              : _vm.currencies[0],
                            example: _vm.example
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    { staticClass: "style3" },
                    [
                      _c("rate-cell", {
                        attrs: {
                          rate: _vm.ratePV[2],
                          group: _vm.group,
                          currency: _vm.account.currency
                            ? _vm.account.currency
                            : _vm.currencies[0],
                          example: _vm.example
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(2, function(i) {
                    return _c(
                      "b-td",
                      { key: "c" + i, staticClass: "style2" },
                      [
                        _c("rate-cell", {
                          attrs: {
                            rate: _vm.ratePV[i + 2],
                            group: _vm.group,
                            currency: _vm.account.currency
                              ? _vm.account.currency
                              : _vm.currencies[0],
                            example: _vm.example
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }