<template>
    <div>
        <header-main></header-main>
        <component
            v-if="page"
            v-bind:is="page"
            name="page"
        />
        <footer-main></footer-main>
    </div>
</template>

<script>
    import Account from "./pages/front/subscription/Account";
    import Calcul from "./pages/front/estimate/Calcul";
    import Confirmation from "./pages/front/subscription/Confirmation";
    import Devis from "./pages/front/estimate/Devis";
    import Discipline from "./pages/front/domains/Discipline";
    import Disciplines from "./pages/front/estimate/Disciplines";
    import Domain from "./pages/front/domains/Domain";
    import Droits from "./pages/front/estimate/Droits";
    import Enterprise from "./pages/front/subscription/Enterprise";
    import Example from "./pages/front/subscription/Example";
    import ExamplePayment from "./pages/front/subscription/ExamplePayment";
    import FooterMain from "./components/footer/Main";
    import HeaderMain from "./components/header/Main";
    import Home from "./pages/front/Home";
    import Page from "./pages/front/Page";
    import Payment from "./pages/front/subscription/Payment";
    import Profil from "./pages/front/subscription/Profil";
    import Register from "./pages/front/subscription/Register";
    import Lost from "./pages/front/subscription/Lost";
    import Recovery from "./pages/front/subscription/Recovery";

    export default {
        name: "AppFront",
        //props: ["accessLogin", "initSession", "logout"],
        components: {Account, Calcul, Confirmation, Devis, Discipline, Disciplines, Domain, Droits, Enterprise,
            Example, ExamplePayment, FooterMain, HeaderMain, Home, Page, Payment, Profil, Register, Lost, Recovery},
        data() {
            return {
                price: null,
                options: null,
                priceOptions: null,
            }
        },
        created() {
            this.$store.commit('setAdmin', false)
        },
        computed: {
            user() {
                return this.$store.state.currentUser
            },
            page() {
                return this.$route.meta.page
            }
        },
        methods: {
            /*getAccount() {
                if(this.user) {
                    const url = this.$store.state.apiUrl + "/api/clients?email=" + this.user.email
                    axios.get(
                        url,
                        {
                            'headers': {
                                'Authorization': 'Bearer ' + this.$store.state.accessToken
                            }
                        }
                    ).then(response => {
                        this.account = response.data["hydra:member"][0]
                        if(!this.account) {
                            this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                        } else {
                            this.$store.commit("setCurrentPrice", this.account.lastOrder.price.id)
                        }
                        return this.account
                    })
                } else {
                    this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                }
            }*/
        }
    }
</script>

<style scoped>

</style>
