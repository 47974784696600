var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tab-input", {
    attrs: {
      url: "copyright_types",
      title: "Copyright types",
      "add-text": "add a copyright type",
      "make-toast": _vm.makeToast,
      "title-notification": "Type Notification",
      name: "CopyrightType",
      "generate-file": "copyrighttypes"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }