<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col class="pr-0">
                    <b-input-group size="sm" append="%">
                        <b-form-select
                            size="sm"
                            v-model="rate"
                            @change="changeValue()"
                        >
                            <b-form-select-option
                                v-for="i in 50"
                                :key="i"
                                :value="i"
                            >
                                {{ i }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-col>
                <b-col class="text-nowrap pr-0 pt-2">
                    <span v-if="index === 0">
                        {{ $t('website.04_p03_inferieure') }}

                    </span>
                    <span v-else-if="index === modelH.length-1">
                        {{ $t('website.04_p03_superieure') }}
                    </span>
                    <span v-else>
                        {{ $t('website.04_p03_entre') }}
                        {{ limiteInferieure }}
                        {{ $t('website.04_p03_et') }}
                    </span>
                </b-col>
                <b-col class="pl-0">
                    <b-input-group size="sm" :append="currency" v-if="index < modelH.length-1">
                        <b-input
                            size="sm"
                            v-model="ca"
                            @change="changeValue()"
                        />
                    </b-input-group>
                    <div v-else class="pt-2">
                        {{ limiteInferieure }} {{ currency }}
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="4" class="text-right pt-2">{{ value |formatNumber }} {{ currency }}</b-col>
    </b-row>
</template>

<script>
    import {serverBus} from "../../../../app";

    export default {
        name: "LineRedevance",
        props: ['index', 'modelH', 'currency', 'name', 'total'],
        data() {
            return {
                rate: null,
                ca: null,
                value: 0,
                rateRedevance: 'rate1',
                limiteInferieure: null,
            }
        },
        mounted() {
            this.initialValues()
            this.checkCalculsRedevance()
            serverBus.$on(this.name, (isChanged) => {
                if(isChanged) {
                    this.checkCalculsRedevance()
                }
            })
            serverBus.$on('rateRedevance', (val) => {
                this.rateRedevance = val
                this.changeRedevance()
                this.setValue()
            })
        },
        methods: {
            initialValues() {
                this.rate = this.modelH[this.index][this.rateRedevance]
                this.ca = this.modelH[this.index]['ca']
                if(this.index > 0) {
                    this.limiteInferieure = this.modelH[this.index-1]['ca']
                }
                if(this.index === this.modelH.length-1) {
                    this.ca = this.limiteInferieure
                }
            },
            setValue() {
                let calcul = this.$store.state.calcul
                const total = calcul[this.name]['numbers']*calcul[this.name]['price']
                let val = Math.round(this.rate * total / 100)
                if(this.index === 0) {
                    this.value = (total < this.ca) ?
                        Math.round(this.rate*total/100) :
                        Math.round(this.rate*this.ca/100)
                }
                if(this.index > 0 && this.index < this.modelH.length-1) {
                    this.limiteInferieure = calcul[this.name][this.index-1]['ca']
                    if (total > calcul[this.name][this.index-1]['ca']) {
                        this.value = (total < this.ca) ?
                            Math.round(this.rate * (total - calcul[this.name][this.index - 1]['ca']) / 100) :
                            Math.round(this.rate * (this.ca - calcul[this.name][this.index - 1]['ca']) / 100)
                    } else {
                        this.value = 0
                    }

                }
                if(this.index === this.modelH.length-1 && total > calcul[this.name][this.index-1]['ca']) {
                    if(total > calcul[this.name][this.index-1]['ca']) {
                        this.limiteInferieure = calcul[this.name][this.index-1]['ca']
                        this.ca = this.limiteInferieure
                        this.value = Math.round(this.rate*(total-calcul[this.name][this.index-1]['ca'])/100)
                    } else {
                        this.value = 0
                    }
                }
                this.setRedevance()
            },
            checkCalculsRedevance() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name] && calcul[this.name][this.index]) {
                    const calculObject = calcul[this.name][this.index]
                    if(calculObject['ca'] >= 0) {
                        this.ca = calculObject['ca']
                        this.rate = calculObject['rate']
                    }
                }
                if(calcul && calcul[this.name]) {
                    this.setValue()
                }
            },
            changeRedevance() {
                let calcul = this.$store.state.calcul
                if(calcul && calcul[this.name]) {
                    const calculObject = calcul[this.name]
                    if(calculObject['rights'] >= 0) {
                        this.rateRedevance = "rate" + calculObject['rights']
                        this.rate = this.modelH[this.index][this.rateRedevance]
                    }
                }
            },
            setRedevance() {
                let calcul = this.$store.state.calcul
                if(!calcul[this.name][this.index]) {
                    calcul[this.name][this.index] = {}
                }
                calcul[this.name][this.index]['rate'] = this.rate
                calcul[this.name][this.index]['ca'] = this.ca
                calcul[this.name][this.index]['value'] = this.value

                this.$store.commit('setElement', {'key': 'calcul', 'value': calcul})
                serverBus.$emit('redevanceCalcul', true)
            },
            changeValue() {
                this.setValue()
                serverBus.$emit(this.name, true)
            }
        },
    }
</script>

<style scoped>

</style>
