var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-observer", {
    ref: "observer",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return handleSubmit(_vm.onSubmit)
                  }
                }
              },
              [
                _c(
                  "b-container",
                  [
                    _c("div", {
                      staticClass: "titre mb-2 mt-5",
                      domProps: { innerHTML: _vm._s(_vm.$t("home.mdp-menu")) }
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form",
                              { attrs: { inline: "" } },
                              [
                                _c("b-form-input", {
                                  attrs: { size: "sm" },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.errorCode
                              ? _c(
                                  "div",
                                  [
                                    _c("b-alert", {
                                      attrs: {
                                        show: "",
                                        variant: "danger",
                                        size: "sm"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("website." + _vm.errorCode)
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("bottom-actions", {
                  attrs: {
                    "button-text": _vm.$t("website.page_souscrire_bt_verifier"),
                    "set-action": _vm.setAction
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }