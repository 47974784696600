<template>
    <b-container class="text-center mt-3 mb-3 text-primary">
        <router-link :to="{ name: 'mentions', params: {lng: lng, slug: 'mentions_et_conditions'} }">{{ $t('website.mentions') }}</router-link> |
        <router-link :to="{ name: 'preambule', params: {lng: lng, slug: 'preambule'} }">{{ $t('website.preambule') }}</router-link> |
        <router-link :to="{ name: 'aide', params: {lng: lng, slug: 'aide'} }">{{ $t('website.aide') }}</router-link>  |
        <span v-html="$t('website.contact')" /> |
        © 2008-{{ year }} <a href="http://www.moneydesign.org/" target="_blank">Moneydesign</a> |
        <span v-html="$t('home.cooperationafd')" />
    </b-container>
</template>

<script>
    export default {
        name: "BottomLinks",
        computed: {
            lng() {
                return this.$store.state.actLng
            },
            year() {
                return this.$moment().format('YYYY')
            },
        }
    }
</script>

<style scoped>
</style>
