<template>
    <div>
        <b-modal
            id="modalLost"
            :title="$t('home.mdp-menu')"
            hide-footer
        >
            <div v-if="error">
                <b-icon icon="exclamation-circle-fill" variant="danger" font-scale="2" /> {{ $t('lostpwd.lost-error') }}
            </div>
            <div v-else-if="loading">
                <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="4"></b-icon>
            </div>
            <validation-observer ref="observer" v-slot="{ handleSubmit }" v-else-if="!sent">
                <div class="mb-3">
                    {{ $t('lostpwd.lost-explain') }}
                </div>
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                    <b-container>
                        <b-row>
                            <b-col cols="12">
                                <validation-provider
                                    :name="field.key"
                                    :rules="field.rule"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        id="example-input-group"
                                        :invalid-feedback="validationContext.errors[0]"
                                    >
                                        <b-form-input
                                            id="input-small"
                                            name="input-small"
                                            v-model="form[field.key]"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="input-live-feedback"
                                            type="text"
                                            :placeholder="$t('website.page_souscrire_email')"
                                        />
                                    </b-form-group>
                                </validation-provider>
                                <b-button
                                    variant="primary"
                                    v-html="$t('website.valider')"
                                    type="submit"
                                    class="mb-1 mt-1"
                                />
                                <div v-if="errorCode">
                                    <b-alert show variant="danger" size="sm" v-html="$t('website.'+errorCode)"/>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form>
            </validation-observer>
            <div v-else v-html="sentmsg"/>
        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "modalLost",
        data() {
            return {
                field: {name: 'email', key: 'email', show: true, rule: 'required|email'},
                errorCode: null,
                form: {
                    email: null
                },
                loading: false,
                error: false,
                sent: false
            }
        },
        mounted() {
            this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
                this.sent = false
                this.error = false
                this.loading = false
            })
        },
        computed: {
            sentmsg() {
                return this.$t('lostpwd.lost-sent').replace('##email##', this.form.email)
            }
        },
        methods: {
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            onSubmit() {
                this.checkEmailClient(this.form.email)
            },
            checkEmailClient(email) {
                this.loading = true
                const url = this.$store.state.apiUrl + '/api/front/check_email?email=' + email
                axios.get(
                    url
                ).then(
                    response => {
                        if(response.data["hydra:totalItems"] > 0 ) {
                            this.getUniqId(response.data["hydra:member"][0])
                        } else {
                            this.error = true
                        }
                    }
                )
            },
            getUniqId(client) {
                let params = {}
                params.email = client.email
                const url = '/email/uniq-id'
                axios.post(
                    url,
                    params,
                    {
                      'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
                    }
                ).then(
                    token => {
                        this.setLostPassword(client, token.data)
                    }
                )
            },
            setLostPassword(client, token) {
                let params = {}
                params.client = client["@id"]
                params.token = token
                //console.log("setLostPassword token", token)
                const url = this.$store.state.apiUrl + '/api/lost_passwords'
                axios.post(
                    url,
                    params,
                    {
                      'headers': {
                          'Content-Type': this.$store.state.postHeader,
                      }
                    }
                ).then(
                    response => {
                        this.sendEmail(client.email, token)
                    }
                ).catch(
                    error => {
                        this.error = true
                    }
                )
            },
            sendEmail(email, token) {
                let params = {}
                params.email = email
                params.token = token
                //console.log("sendEmail token", token)
                const url = '/email/lost-password'
                axios.post(
                    url,
                    params,
                    {
                      'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
                    }
                ).then(
                    response => {
                        this.loading = false
                        this.sent = true
                    }
                )
            }
        },
    }
</script>

<style scoped>

</style>
