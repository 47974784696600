var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account
    ? _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("div", { staticClass: "mb-3" }, [
            _c("span", {
              staticClass: "font-weight-bold",
              domProps: { innerHTML: _vm._s(_vm.$t("website.04_p01_titre")) }
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.$t("website.04_p01_titre2")) }
            })
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _vm._l(_vm.types, function(type, i) {
                return _c("droit-cell", {
                  key: type.id,
                  attrs: {
                    index: i,
                    "model-h": type.title,
                    name: _vm.name,
                    total: _vm.totalHonoraires,
                    currency: _vm.account.currency
                      ? _vm.account.currency.symbol
                      : "€",
                    type: "LineExploitation"
                  }
                })
              }),
              _vm._v(" "),
              _c("droit-total-cell", {
                attrs: {
                  currency: _vm.account.currency
                    ? _vm.account.currency.symbol
                    : "€",
                  total: _vm.totalExploitation,
                  account: _vm.account
                }
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("b-col"),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "5" } },
            [_c("droit-supports", { attrs: { name: _vm.name } })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }