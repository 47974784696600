<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" v-if="account">
                <b-container>
                    <b-row class="mt-5 mb-5">
                        <b-col sm="12" md="6">
                            <div class="titre mb-4" v-html="$t('website.00b_compteck')"></div>
                            <div class="row">
                                <form-subscription :account="account" :form="form"/>
                            </div>
                        </b-col>
                        <b-col sm="12" md="1"/>
                        <b-col sm="12" md="5">
                            <b-row v-if="order.active" v-for="order in account.orders" :key="order.id">
                                <b-col v-if="order.domain">
                                    <span class="priceName" v-if="order.price">{{ order.price.name }}</span>
                                    <span v-if="order.domain" v-html="' ('+ $t('domaines.' + order.domain.title) +')'"/>
                                    <span
                                        class="legende"
                                        v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateend"
                                    />
                                    <b-icon-exclamation-triangle-fill variant="danger" v-if="validationDate < 0"/>
                                </b-col>
                                <b-col v-else>
                                    <div v-if="!order.example">
                                        <span class="priceName">{{ order.price.name }}</span>
                                        <span
                                            class="legende"
                                            v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateend"
                                        />
                                        <b-icon-exclamation-triangle-fill variant="danger" v-if="validationDate < 0"/>
                                    </div>
                                </b-col>
                            </b-row>
<!--                            <b-row v-if="account.lastOrder">
                                <b-col>
                                    <span class="priceName">{{ account.lastOrder.price.name }}</span>
                                    <span v-if="account.lastOrder.domain" v-html="' ('+ $t('domaines.' + account.lastOrder.domain.title) +')'"/>
                                    <span
                                        class="legende"
                                        v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateend"
                                    />
                                    <b-icon-exclamation-triangle-fill variant="danger" v-if="validationDate < 0"/>
                                </b-col>
                            </b-row>-->
                            <b-row v-if="account.enterprise && account.enterprise.lastOrder">
                                <b-col>
                                    <span class="priceName" v-if="account.enterprise.lastOrder.price">{{ account.enterprise.lastOrder.price.name }}</span>
                                    <span> - {{ account.enterprise.society }}</span>
                                    <div
                                        class="legende"
                                        v-html="$t('tarifs.abonnementannuel') + ' ' + $t('home.jusquau') + ' ' + dateend"
                                    />
                                </b-col>
                            </b-row>
                            <b-row class="mt-3" v-if="account.lastOrder && account.lastOrder.price && account.lastOrder.price.name === 'education'">
                                <b-col>
                                    <router-link :to="{ name: 'enterprise', params: {lng: $store.state.actLng }}">
                                        <b-button variant="primary" size="sm" v-html="$t('tarifs.gestion_entreprise')"/>
                                    </router-link>
                                </b-col>
                            </b-row>
                            <b-row v-if="examples && examples.length > 0" class="mt-2">
                                <b-col>
                                    <div v-html="$t('website.exemples_achetes')"/>
                                    <div v-for="(order,index) in examples" :key="order.id">
    <!--                                    {{ order.example }}-->
                                        &bull; <span v-html="$t('domaines.' + order.example.topic.domain.title)"/> / <span v-html="$t('domaines.' + order.example.topic.title)"/> / <span v-html="$t('examples.' + order.example.title)"/>
                                        <span v-html="$t('home.jusquau')"/>
                                        <span>{{ order.validationDate |moment('DD/MM/YYYY') }}</span>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3"
                                   v-if="account.lastOrder && account.lastOrder.price && (account.lastOrder.price.name === 'free' || account.lastOrder.price.name === 'solo')"
                            >
                                <b-col>
                                    <router-link :to="{ name: 'example', params: {lng: $store.state.actLng }}">
                                        <b-button variant="primary" size="sm" v-html="$t('tarifs.achat_exemple_unite')"/>
                                    </router-link>
                                </b-col>
                            </b-row>
                            <div class="mt-4"v-if="account.lastOrder">
                                <price-option :price="account.lastOrder.price" :domain-selected="account.lastOrder.domain"/>
                                <div class="text-right mt-2">
                                    <router-link :to="{name: 'home', params: {lng: $store.state.actLng }}">
                                        <b-button
                                            :variant="(validationDate < 0) ? 'danger' : 'success'"
                                            class="text-uppercase"
                                            size="sm"
                                            v-html="(validationDate < 0) ? $t('tarifs.renew') : $t('tarifs.upgrade')"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <hr class="mt-4 mb-4"/>
                            <b-row>
                                <b-col sm="12" md="6">
                                    <div v-html="$t('home.labelwinwin')"/>
                                    <div class="mt-1">
                                        <a href="/Label-CK-Win-Win.pdf" target="_blank">
                                            <i class="icon-ICOwinwin" style="float:left !important;"/>
                                        </a>
                                    </div>
                                </b-col>
                                <b-col sm="12" md="6">
                                    <div v-html="$t('home.facture')" v-if="account.lastOrder"/>
                                    <div v-for="(order,index) in account.orders" :key="order.id">
                                        <a href="#" v-if="order.paymentDate" @click="generateBill(order)">
                                            <span v-html="$t('website.votrefacturedu')"/>
                                            {{ order.paymentDate | moment('DD/MM/YYYY') }}
                                        </a>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <vue-html2pdf
                        :show-layout="false"
                        :enable-download="false"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="confirmation"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        :pdf-content-width="800"
                        v-if="account.orders"

                        ref="orderPdf"
                    >
                        <order-bill slot="pdf-content" :account="account" :order="order"/>

                    </vue-html2pdf>
                </b-container>
                <bottom-actions
                    :button-text="$t('website.go')"
                    :set-action="setAction"
                />
            </b-form>
            <b-container v-else>
                <b-row class="mt-5 mb-5">
                    <b-col class="text-center">
                        <Loading/>
                    </b-col>
                </b-row>
            </b-container>
        </validation-observer>
        <message :msg="msg" :type-message="typeMessage"/>
    </div>
</template>

<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import FormSubscription from "../../../components/form/Subscription";
    import OrderBill from "../../../components/pdf/OrderBill";
    import PriceOption from "../../../components/front/PriceOption";
    import BottomActions from "../../../components/footer/BottomActions";
    import axios from "axios";
    import Loading from "../../../components/front/Loading";
    import Message from "../../../components/form/Message";

    export default {
        name: "Account",
        data() {
            return {
                msg: null,
                typeMessage: 'success',
                account: null,
                order: null,
                examples: null,
                form: {
                    lastname: null,
                    firstname: null,
                    job: null,
                    society: null,
                    address: null,
                    zipcode: null,
                    city: null,
                    country: null,
                    vatNumber: null,
                    phone: null,
                    email: null,
                },
            }
        },
        components: {Message, Loading, BottomActions, OrderBill, FormSubscription, PriceOption, VueHtml2pdf},
        computed: {
            dateend() {
                if(this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).format('DD/MM/YYYY')
                }
                if(this.account.enterprise && this.account.enterprise.lastOrder) {
                    return this.$moment(this.account.enterprise.lastOrder.validationDate).format('DD/MM/YYYY')
                }
                return
            },
            validationDate() {
                if(this.account && this.account.lastOrder) {
                    return this.$moment(this.account.lastOrder.validationDate).diff(this.$moment())
                }
                return
            },
        },
        created() {
            if(!this.$store.state.currentUser) {
                this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                return false
            }
            if(!this.$store.state.accessToken) {
                this.$router.push({name: 'paiement', params: {lng: this.$store.state.actLng}})
                return false
            }
            this.$getAccount(this.$store.state.apiUrl, this.$store.state.accessToken, this.$store.state.currentUser).then(
                data => {
                    this.account = data
                    if(!this.account.lastOrder && !this.account.enterprise.lastOrder) {
                        this.$router.push({name: 'home', params: {lng: this.$store.state.actLng}})
                    }
                    this.getExamples()
                }
            )
        },
        mounted() {
            this.$store.commit("setDomainStep", null)
        },
        methods: {
            generateBill(order) {
                this.order=order
                this.$refs.orderPdf.generatePdf()
            },
            setAction(name) {
                this.buttonName = name
            },
            onSubmit() {
                let updateAccount = {}
                Object.keys(this.form).forEach((key) => {
                    updateAccount[key] = this.form[key]
                })
                updateAccount.country = this.form.country['@id']
                const url = this.$store.state.apiUrl + this.account["@id"]
                axios.patch(
                    url,
                    updateAccount,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.msg = this.$t('website.majcompte')
                        this.typeMessage = 'success'
                    }
                ).catch(
                    error => {
                    }
                )
            },
            getExamples() {
                const url = this.$store.state.apiUrl + '/api/orders?exists[example]=true&client=' + this.account["@id"]
                axios.get(
                    url,
                    {
                        'headers': {
                            'Content-Type': this.$store.state.patchHeader,
                            'Authorization': 'Bearer ' + this.$store.state.accessToken
                        }
                    }
                ).then(
                    response => {
                        this.examples = response.data['hydra:member']
                    }
                )
            }
        },
    }
</script>

<style scoped>

</style>
