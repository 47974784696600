var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "titre mb-2",
      domProps: { innerHTML: _vm._s(_vm.$t("website.aide")) }
    }),
    _vm._v(" "),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.$t("website.00d_aide")) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }