<template>
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped>

</style>
