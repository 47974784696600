<template>
    <b-container v-if="msg">
        <b-row>
            <b-col class="text-center mt-2">
                <b-alert show :variant="typeMessage" class="mb-0">{{ msg }}</b-alert>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Message",
        props: ['msg', 'typeMessage']
    }
</script>

<style scoped>

</style>
